import { FunctionComponent, h } from "preact"

import style from "./user-roles.scss"

type UserRolesProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  onHide: () => void
}

const UserRoles: FunctionComponent<UserRolesProps> = ({ show, onHide }) => {
  return (
    <div
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content card">
          <div className="card-body d-flex justify-content-between">
            <h5 class="card-title">User Roles</h5>
            <div>
              <button
                style={{ backgroundColor: "unset", border: "none" }}
                className="ms-2"
                onClick={onHide}
              >
                x
              </button>
            </div>
          </div>
          <div className="card-body px-4">
            <h5 className={style.title}>Headquarters</h5>
            <div className={style.card}>
              <div className={style.icon}>
                <img src="/assets/icons/profile-green-check.png" />
              </div>
              <div className={style.item}>
                <div className={style.layer}>Layer 1</div>
                <div className={style.role}>Admin</div>
              </div>
              <div className={style.desc}>
                View data, download dan mengelola dashboard
              </div>
            </div>
            <div className={style.card}>
              <div className={style.icon}>
                <img src="/assets/icons/profile-green.png" />
              </div>
              <div className={style.item}>
                <div className={style.layer}>Layer 2</div>
                <div className={style.role}>Member</div>
              </div>
              <div className={style.desc}>View data, download </div>
            </div>

            <h5 className={style.title}>Mills</h5>
            <div className={style.card}>
              <div className={style.icon}>
                <img src="/assets/icons/profile-blue.png" />
              </div>
              <div className={style.item}>
                <div className={style.layer}>Layer A</div>
                <div className={style.role}>Operator</div>
              </div>
              <div className={style.desc}>
                View data, download dan membuat NCR
              </div>
            </div>
            <div className={style.card}>
              <div className={style.icon}>
                <img src="/assets/icons/profile-blue.png" />
              </div>
              <div className={style.item}>
                <div className={style.layer}>Layer B</div>
                <div className={style.role}>Manager</div>
              </div>
              <div className={style.desc}>
                View data, download dan approve NCR
              </div>
            </div>
            <div className={style.card}>
              <div className={style.icon}>
                <img src="/assets/icons/profile-blue.png" />
              </div>
              <div className={style.item}>
                <div className={style.layer}>Layer C</div>
                <div className={style.role}>Div Head</div>
              </div>
              <div className={style.desc}>
                View data, download dan approve NCR
              </div>
            </div>
            <div className={style.card}>
              <div className={style.icon}>
                <img src="/assets/icons/profile-blue.png" />
              </div>
              <div className={style.item}>
                <div className={style.layer}>Layer D</div>
                <div className={style.role}>Sustainability Div</div>
              </div>
              <div className={style.desc}>
                View data, download dan validasi NCR
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserRoles
