import { FunctionComponent, h } from "preact"

import { ApiResponse } from "../../../types/api-response"
import { AxiosError } from "axios"
import { Button } from "../../../parts/buttons"
import useAuth from "../../../hooks/useAuth"
import { useEffect } from "preact/hooks"
import useObjectState from "../../../hooks/useObjectState"

type ChangePasswordProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean
  onHide: () => void
  logOut: () => void
}

const ChangePassword: FunctionComponent<ChangePasswordProps> = ({ show, onHide, logOut }) => {
  const { fetcher, isLogin } = useAuth()
  const [data, setData] = useObjectState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  })
  const [alert, setAlert] = useObjectState({ value: "", type: "error" as "error" | "success" })
  const handleSubmit = () => {
    isLogin().then((user) => {
      if (!user) return setAlert({ value: "Login please.", type: "error" })
      const url = `/auth/change-password/${user.uuid}`
      fetcher()
        .put<ApiResponse<{ message: string }>>(url, data)
        .then(({ data }) => data.data)
        .then(() => {
          setAlert({ type: "success", value: "Password has been changed. Please Re-Login" })
          setTimeout(() => {
            if (logOut) logOut()
          }, 4000)
        })
        .catch(
          (
            er: AxiosError<{
              message: string | string[]
              data: { message: string | string[] }
            }>
          ) => {
            let msg = er.response?.data?.message
            if (Array.isArray(msg)) msg = msg.join(",  ")
            const errorMessage = msg || er.response?.data?.data?.message?.toString() || "Something error"
            const value = errorMessage
              .replace(/old_password/g, "Old Password")
              .replace(/confirm_password/g, "Confirmation Password")
              .replace(/new_password/g, "New Password")
            setAlert({ type: "error", value })
          }
        )
    })
  }

  useEffect(() => {
    setAlert({ value: "" })
  }, [])
  return (
    <div>
      <div
        className="modal-backdrop"
        style={{
          display: show ? "inherit" : "none",
          backgroundColor: "#00000099",
          overflowY: "auto",
          color: "black",
        }}
      >
        <div class="modal-dialog modal-lg modal-dialog-centered " role="document">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
            class="modal-content card"
          >
            <div className="card-body">
              <h5 className="text-black">Change Password</h5>
            </div>
            <div className="card-body">
              {alert.value && <div className={`alert alert-${alert.type}`}>{alert.value}</div>}
              <div>
                <div className="label">Enter Old Password</div>
                <input
                  className="form-control mb-3"
                  value={data.old_password}
                  onInput={({ currentTarget }) => setData({ old_password: currentTarget.value })}
                  type="password"
                />
              </div>
              <div>
                <div className="label">New Password</div>
                <input
                  className="form-control mb-3"
                  value={data.new_password}
                  onInput={({ currentTarget }) => setData({ new_password: currentTarget.value })}
                  type="password"
                />
              </div>
              <div>
                <div className="label">Enter New Password</div>
                <input
                  className="form-control mb-3"
                  value={data.confirm_password}
                  onInput={({ currentTarget }) => setData({ confirm_password: currentTarget.value })}
                  type="password"
                />
              </div>
            </div>
            <div className="card-body d-flex justify-content-end">
              <div>
                <Button variant="primary" type="submit">
                  Change Password
                </Button>
                <Button variant="tertiary" className="ms-2" type="button" onClick={onHide}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
