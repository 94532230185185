import { ApiResponse } from "../types/api-response";
import { Role } from "../enum/roles.enum";
import useAxios from "./useAxios";
import { AUTH_TOKEN, AUTH_USER } from "../constants";
import { useState, useEffect } from "preact/hooks";

type ResponseToken = ApiResponse<{
  access_token: string;
  user: User;
}>;

type User = {
  uuid: string;
  name: string;
  email: string;
  password: string;
  salt: string;
  role: Role;
  notification: boolean;
  notification_interval: number;
  created_at: string;
  update_at: string;
  company: string;
  mil: any;
  area: string;
  cems: any[];
};

const useAuth = () => {
  const key = AUTH_TOKEN;
  const userKey = AUTH_USER;
  const axios = useAxios();
  const url = "/auth/sign-in";
  const [user, setUser] = useState<User | null>(null);

  const getToken = () => {
    if (localStorage) return localStorage.getItem(key);
    return "";
  };

  const setToken = (token: string) => {
    if (localStorage) return localStorage.setItem(key, token);
  };

  const setUserId = (uuid: string) => {
    if (localStorage) return localStorage.setItem(userKey, uuid);
  };

  const getUserId = () => {
    if (localStorage) return localStorage.getItem(userKey);
    return "";
  };

  const login = async (email: string, password: string) => {
    return axios.post<ResponseToken>(url, { email, password }).then(({ data }) => {
      setToken(data.data?.access_token);
      setUserId(data.data?.user?.uuid);
      return true;
    });
  };
  const fetcher = () =>
    useAxios({
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

  const isLogin = async () => {
    const token = getToken();
    const uuid = getUserId();
    if (!(token && uuid)) return false;
    const url = `/user/${uuid}`;
    try {
      const data = await fetcher()
        .get<ApiResponse<User>>(url)
        .then(({ data }) => data.data);
      if (!data) return false;
      return data;
    } catch (e) {
      return false;
    }
  };

  const logout = () => {
    setToken("");
    setUserId("");
    return true;
  };

  useEffect(() => {
    isLogin().then((res: any) => setUser(res));
  }, []);

  return { login, fetcher, isLogin, logout, user };
};

export default useAuth;
