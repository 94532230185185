import { FunctionComponent, h } from "preact"
import { OverviewParameter, RawDataResponse } from "../../hooks/useRaw"
import moment from "moment"
import { Chart as ChartJS } from "chart.js"
import { Line } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"
import { OutputFilter } from "../../types/top-filter"
import { Period } from "../../hooks/useTopFilter"

type ChartProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  height?: number
  cems?: string
  mils?: string
  cemsArray?: string[]
  sensorName: string
  chartData: RawDataResponse
  group?: Period
}

const Chart: FunctionComponent<ChartProps> = ({
  height,
  sensorName: parameterName,
  chartData,
  mils: selectedMils,
  cems: selectedCems,
  cemsArray,
  group,
  ...props
}) => {
  const params = parameterName.split("--")
  const isCorrected = params.length > 1
  const sensorName = params.length > 1 ? params[1] : params[0]
  ChartJS.register(annotationPlugin)
  const data = chartData
    ?.map((com) =>
      com.mils
        .filter(({ uuid }) => uuid == selectedMils || !selectedMils)
        .map((mil) =>
          mil.cems
            .filter(({ uuid }) => uuid == selectedCems || !selectedCems)
            .filter(({ uuid }) => !cemsArray || cemsArray?.includes(uuid))
        )
        .reduce((last, cur) => last.concat(cur), [])
    )
    .reduce((last, cur) => last.concat(cur), [])
    .sort((a, b) => (cemsArray?.indexOf(a.uuid) || 0) - (cemsArray?.indexOf(b.uuid) || 0))

  const viewsData = data
    .map((cem, idx) => {
      const sensor = cem.parameters.find((sensor) => sensor.name == sensorName) as OverviewParameter
      return {
        isSensor: sensor?.name,
        label: `${cem.name}`,
        data: sensor?.payloads?.map((v) => ({
          y: isCorrected ? v.value_condition_override : v.value,
          x: v.measured_at,
        })),
        borderWidth: 1,
        borderColor: `hsl(${55 * idx}, 100%, 50%)`,
        backgroundColor: `hsla(${55 * idx}, 100%, 50%, 0.2)`,
      }
    })
    .filter(({ isSensor }) => isSensor)
    .map((item) => {
      const data = item.data
      return {
        ...item,
        data: data
          .map(({ x, y }) => ({
            y,
            x:
              group == "DAILY"
                ? moment(x).startOf("day").toISOString()
                : group == "HOURLY"
                ? moment(x).startOf("hour").toISOString()
                : x,
          }))
          .reduce<{ x: string; y: number; c: number }[]>((acc, now) => {
            const idx = acc.map(({ x }) => x).indexOf(now.x)
            if (idx > -1) {
              const n = acc[idx]
              acc[idx] = { y: (n.c * Number(n.y) + Number(now.y)) / (n.c + 1), x: now.x, c: n.c + 1 }
              return acc
            }
            acc.push({ x: now.x, y: Number(now.y), c: 1 })
            return acc
          }, [])
          .map(({ x, y }) => ({ x, y })),
      }
    })

  // console.log(viewsData)

  const thParams = data
    ?.map((cem) => {
      const sensor = cem.parameters.find((sensor) => sensor.name == sensorName) as OverviewParameter
      const out = { ...sensor, name: cem.name }
      return out
    })
    .filter((sensor) => sensor?.upper_threshold || sensor?.lower_threshold)
    .filter((v, i, arr) => arr.map(({ uuid }) => uuid).indexOf(v.uuid) == i)

  const upper = thParams
    .filter((sensor) => sensor?.upper_threshold)
    .reduce((a, b) => {
      const idx = a.map(({ upper_threshold }) => upper_threshold).indexOf(b.upper_threshold)
      if (idx < 0) a.push(b)
      else {
        let old = { ...a[idx] }
        old.name = old?.name + ", " + b?.name
        a[idx] = old
      }
      return a
    }, [] as { uuid: string; name: string; upper_threshold: number; lower_threshold: number }[])
    .map(({ name, upper_threshold }) => ({
      type: "line" as "line",
      yMin: upper_threshold,
      yMax: upper_threshold,
      borderColor: "gray",
      borderWidth: 2,
      borderDash: [8],
      label: {
        content: name + " - Upper Threshold",
        position: "start" as "start",
        enabled: true,
      },
    }))

  const lower = thParams
    .filter((sensor) => sensor?.lower_threshold)
    .reduce((a, b) => {
      const idx = a.map(({ lower_threshold }) => lower_threshold).indexOf(b.lower_threshold)
      if (idx < 0) a.push(b)
      else {
        let old = { ...a[idx] }
        old.name = old.name + ", " + b.name
        a[idx] = old
      }
      return a
    }, [] as { uuid: string; name: string; upper_threshold: number; lower_threshold: number }[])
    .map(({ name, lower_threshold }) => ({
      type: "line" as "line",
      yMin: lower_threshold,
      yMax: lower_threshold,
      borderColor: "gray",
      borderWidth: 2,
      borderDash: [8],
      label: {
        content: name + " - Lower Threshold",
        position: "start" as "start",
        enabled: true,
      },
    }))

  return (
    <div {...props}>
      <Line
        height={height}
        options={{
          scales: {
            x: {
              type: "timeseries",
              display: true,
              time: {
                displayFormats: {
                  minute: "YYYY-MM-DD HH:mm",
                  millisecond: "YYYY-MM-DD HH:mm",
                  second: "YYYY-MM-DD HH:mm",
                  hour: "YYYY-MM-DD HH:mm",
                  day: "YYYY-MM-DD HH:mm",
                  week: "YYYY-MM-DD HH:mm",
                  month: "YYYY-MM-DD HH:mm",
                  quarter: "YYYY-MM-DD HH:mm",
                  year: "YYYY-MM-DD HH:mm",
                },
              },
              title: {
                display: true,
                text: "Time",
              },
            },
          },
          plugins: {
            annotation: {
              annotations: upper
                .concat(lower)
                .map((v, idx) => ({
                  ["th_" + idx]: v,
                }))
                .reduce((a, b) => ({ ...a, ...b }), {}),
            },
          },
        }}
        data={{
          datasets: [...viewsData],
        }}
      />
    </div>
  )
}

export default Chart
