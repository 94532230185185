import { FunctionComponent, h } from "preact";
import { useEffect, useRef } from "preact/hooks";
import { useState } from "preact/hooks";

import { ApiResponse } from "../../../types/api-response";
import { Button } from "../../../parts/buttons";
import { KLHKResponse } from "../../../hooks/useAllklhk";
import TopBar from "../../../components/topbar";
import { mutate } from "swr";
import useAuth from "../../../hooks/useAuth";
import useCems from "../../../hooks/useCems";
import useMills from "../../../hooks/useMills";
import useObjectState from "../../../hooks/useObjectState";
import { Area } from "../../../enum/area.enum";
import RenderIf from "../../../components/RenderIf";
import { TailSpin } from "react-loader-spinner";

type AddKLHKProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  onBack?: () => void;
  uuid?: string;
  editAt?: number;
};

const defaultBody = {
  mil: { uuid: "" },
  cem: { uuid: "" },
  area: "",
  parameter: "",
  description: "",
  completion_date: "",
  file: "",
};

const AddKLHK: FunctionComponent<AddKLHKProps> = ({ onBack, uuid: klhkId, editAt }) => {
  const { user } = useAuth();
  const [body, setBody] = useObjectState<any>(defaultBody);
  const { data: mills } = useMills();
  const area =
    body.area.length !== 0 ? body.area : user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant;
  const { data: cems } = useCems("", area);
  const { fetcher } = useAuth();
  const [loading, setLoading] = useState(false);

  const file = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    setLoading(true);
    if (!klhkId) {
      const form = new FormData();
      if (file.current?.files?.length) form.append("file", file.current?.files[0]);
      fetcher()
        .post("/manual-report/file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => data?.data?.path as string)
        .then((path) => {
          return fetcher().post("/ncr-klhk", { ...body, file: path || "" });
        })
        .catch(console.error)
        .finally(() => {
          mutate("/ncr-klhk");
          setLoading(false);
          if (onBack) onBack();
        });
    } else {
      let path = body.file;
      if (file.current?.files?.length) {
        const form = new FormData();
        form.append("file", file.current?.files[0]);
        path = await fetcher()
          .post("/manual-report/file", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => data?.data?.path as string);
      }
      fetcher()
        .patch("/ncr-klhk/" + klhkId, { ...body, file: path || body.file })
        .catch(console.error)
        .finally(() => {
          mutate("/ncr-klhk");
          setLoading(false);
          if (onBack) onBack();
        });
    }
  };

  useEffect(() => {
    if (klhkId)
      fetcher()
        .get<ApiResponse<KLHKResponse>>("/ncr-klhk/" + klhkId)
        .then(({ data }) => data.data)
        .then((data) => {
          setBody({
            mil: { uuid: data.mil.uuid },
            cem: { uuid: data.cem.uuid },
            area: data.area,
            completion_date: data.completion_date,
            description: data.description,
            file: data.file,
            parameter: data.parameter,
          });
        })
        .catch(console.error);
  }, [editAt, klhkId]);

  useEffect(() => {}, [body.area]);

  return (
    <div>
      <TopBar title="SISPEK" subtitle="KLHK" onBack={onBack} />
      <div className="p-3">
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="card">
            <div className="card-body h5">{klhkId ? "Edi" : "Add"} KLHK</div>
            <div className="card-body">
              <div className="mb-2">
                <label>Area</label>
                <select
                  required
                  value={body.area}
                  className="form-control rounded-pill"
                  onChange={({ currentTarget: e }) => setBody({ area: e.value })}
                >
                  <RenderIf isTrue={user?.area.toLowerCase() === "all"}>
                    <option value="Power Plant">Power Plant</option>
                    <option value="Non Power Plant">Non Power Plant</option>
                  </RenderIf>
                  <RenderIf isTrue={user?.area.toLowerCase() !== "all"}>
                    {user?.area === Area.NonPowerPlant ? (
                      <option value="Non Power Plant">Non Power Plant</option>
                    ) : (
                      <option value="Power Plant">Power Plant</option>
                    )}
                  </RenderIf>
                </select>
              </div>

              <div className="mb-2">
                <label>Mills</label>
                <select
                  required
                  value={body.mil.uuid}
                  className="form-control rounded-pill"
                  onChange={({ currentTarget: e }) => setBody({ mil: { uuid: e.value } })}
                >
                  {mills
                    ?.filter((mil) => {
                      const cems = mil.cems.filter((cem) => (body.area ? cem.area === body.area : !!cem.uuid));

                      return cems.length !== 0;
                    })
                    .map(({ name, uuid }) => (
                      <option value={uuid} key={uuid}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="mb-2">
                <label>Cems</label>
                <select
                  required
                  value={body.cem.uuid}
                  className="form-control rounded-pill"
                  onChange={({ currentTarget: e }) => setBody({ cem: { uuid: e.value } })}
                >
                  {cems
                    ?.filter(({ mil }) => mil.uuid == body.mil.uuid)
                    .map((cem) => (
                      <option value={cem.uuid} key={cem.uuid}>
                        {cem.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="mb-2">
                <label>Parameter</label>
                <select
                  required
                  value={body.parameter}
                  className="form-control rounded-pill"
                  onChange={({ currentTarget: e }) => setBody({ parameter: e.value })}
                >
                  <option value="All Parameter">All Parameter</option>
                  {cems
                    ?.find(({ uuid }) => uuid == body.cem.uuid)
                    ?.parameters.map((param) => (
                      <option value={param.name} key={param.uuid}>
                        {param.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-2">
                <label>Problem Description</label>
                <textarea
                  required
                  className="form-control"
                  value={body.description}
                  onChange={({ currentTarget: e }) => setBody({ description: e.value })}
                  style={{ maxWidth: "100%", maxHeight: "560px" }}
                ></textarea>
              </div>
              <div className="mb-2">
                <label>Completion Date</label>
                <input
                  type="date"
                  required
                  className="form-control"
                  value={body.completion_date}
                  onChange={({ currentTarget: e }) => setBody({ completion_date: e.value })}
                />
              </div>
              <div className="mb-2">
                <label>Upload KLHK Report</label>
                <input ref={file} type="file" required={klhkId ? false : true} className="form-control" />
              </div>
            </div>
            <div className="card-body d-flex justify-content-end">
              <Button type="submit" className="mx-1" variant="primary">
                <RenderIf isTrue={loading}>
                  <TailSpin
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="d-inline-block me-2"
                  />
                </RenderIf>
                {klhkId ? "Save" : "Add Report"}
              </Button>
              <Button onClick={onBack} type="button" className="mx-1" variant="tertiary">
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddKLHK;
