import { BiBell, BiChevronsLeft, BiMenu } from "react-icons/bi";
import { FunctionComponent, h } from "preact";
import { StateUpdater, useState } from "preact/hooks";

import ChangePassword from "../../routes/dashboard/overview/password";
import Dropdown from "../dropdown";
import { Link } from "preact-router/match";
import NavbarItem from "./item";
import menu from "./menu";
import { route } from "preact-router";
import style from "./index.scss";
import useAuth from "../../hooks/useAuth";
import useModal from "../../hooks/useModal";
import useSWR from "swr";
import RenderIf from "../RenderIf";
import { roleCheck } from "../../utils/permissions";
import useArea from "../../hooks/useArea";

type NavbarProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  collapse: boolean;
  setCollapse: StateUpdater<boolean>;
};

const Navbar: FunctionComponent<NavbarProps> = ({ collapse, setCollapse }) => {
  const [curr, storeArea, removeArea] = useArea();
  const show = !collapse;
  const toggle = () => {
    setCollapse((n) => !n);
  };

  const { isLogin, logout } = useAuth();
  const { data: userData } = useSWR("/me", () => isLogin());

  const me = userData ? userData : undefined;
  const sizeClass = show ? style.show : style.hide;

  const [dropdown, setDropdown] = useState(false);
  const handleLogout = () => {
    removeArea();
    logout();
    route("/");
  };

  const { onHide, onShow, show: showModal } = useModal();

  return (
    <nav className={`bg-navbar text-white d-flex flex-column ${sizeClass} ${style.nav}`}>
      <ChangePassword show={showModal} onHide={onHide} logOut={handleLogout} />
      <div className={style.burger} onClick={toggle}>
        <BiMenu />
      </div>
      <div className={style.brand}>
        <img className={style.logo} src="/assets/img/icon-app.png" style={{filter:"blur(10px)"}} width={70} alt="" />
        <div className={style.name}>
          <div className={style.first}> SISPEK</div>
          {/* <div className={style.last}>SISPEK</div> */}
        </div>
      </div>
      <div className={style.user}>
        <div className="p-3">
          <a href="#" onClick={() => setDropdown((n) => !n)} className="text-white text-decoration-none">
            <div className="p-3 border-bottom border-top text-center">{me?.email}</div>
          </a>
          <Dropdown show={dropdown}>
            <li>
              <a href="#" onClick={onShow} class="dropdown-item">
                Change Password
              </a>
            </li>
            <li>
              <a href="#" onClick={handleLogout} class="dropdown-item">
                Logout
              </a>
            </li>
          </Dropdown>
        </div>
      </div>
      <div className={style.menu}>
        {menu.map((item) => {
          if (item?.permissions) {
            return (
              <RenderIf
                isTrue={roleCheck({
                  roles: item.permissions,
                  currentRole: me?.role,
                })}
              >
                <NavbarItem key={item.label} item={item} />
              </RenderIf>
            );
          }
          return <NavbarItem key={item.label} item={item} />;
        })}
      </div>
      <Link href="/notifications" activeClassName={style.active} className={style.notification}>
        <span className={style.icon}>
          <BiBell />
        </span>
        <span className={style.label}>Notification</span>
      </Link>
      <div className={style.toggle} onClick={toggle}>
        <BiChevronsLeft />
      </div>
    </nav>
  );
};

export default Navbar;
