export const localStorageHelper = {
  load(key: string) {
      const stored = localStorage.getItem(key);
      return stored == null ? undefined : JSON.parse(stored);
  },
  store(key: string, value: string): void {
      localStorage.setItem(key, JSON.stringify(value));
  },
  delete(key: string): void {
    localStorage.removeItem(key)
  },
  modify(key: string, fn: any): void {
      this.store(key, fn(this.load(key)));
  },
  appendItemToArray(item: string, storageID: string): void {
      this.modify(storageID, (storage = []) => [...storage, item]);
  },
  removeItemFromArray(item: string, storageID: string): void {
      this.modify(storageID, (storage = []) => storage.filter(s => s !== item));
  },
  saveItemToObject(item: string, storageID: string): void {
      this.modify(storageID, (storage = {}) => ({...storage, item}));
  },
  //...
};