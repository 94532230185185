import "chartjs-adapter-moment";

import { FunctionComponent, h } from "preact";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { useEffect } from "preact/hooks";
import moment from "moment";

import useAlerts from "../../../hooks/useAlerts";
import useCems from "../../../hooks/useCems";
import useMills from "../../../hooks/useMills";
import useObjectState from "../../../hooks/useObjectState";
import useOverview from "../../../hooks/useOverview";
import usePagination from "../../../hooks/usePagination";
import useRaw from "../../../hooks/useRaw";
import useTopFilter from "../../../hooks/useTopFilter";

import Chart from "../../../components/chart";
import Container from "../../../parts/container";
import MillsTable from "../../../components/mills-table";
import Pagination from "../../../components/pagination";
import TopBar from "../../../components/topbar";
import TopFilter from "../../../components/TopFilter/overview";
import { Button } from "../../../parts/buttons";

import distinctSensor, { CORRECTED, NON_PP_CORRECTED } from "../../../utils/distinctSensor";
import { SensorTypeQuery } from "../../../enum/sensor.enum";
import { Area } from "../../../enum/area.enum";

import style from "./style.scss";
import html2Helper from "../../../utils/html2";
import useArea from "../../../hooks/useArea";
import RenderIf from "../../../components/RenderIf";
import Loading from "../../../components/loading";
import useAuth from "../../../hooks/useAuth";

type DashboardOverviewPageProps = h.JSX.HTMLAttributes<HTMLDivElement>;

type FilterType = {
  mil: string;
  cems: string;
  sensor: string;
  area: any;
  page: number;
  limit: number;
};

const DashboardOverviewPage: FunctionComponent<DashboardOverviewPageProps> = () => {
  const [currentArea, storeArea, removeArea] = useArea(Area.PowerPlant);
  const { user } = useAuth();
  const topFilter = useTopFilter();
  const [filter, setFilter] = useObjectState<FilterType>({
    mil: "",
    cems: "",
    sensor: "",
    area: user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant,
    page: 1,
    limit: 10,
  });

  const { data: mills } = useMills();
  const { data: cems } = useCems("", filter.area);
  const { data: chartData } = useRaw({
    mils: filter.mil,
    type: SensorTypeQuery.overview,
    filter: topFilter.output,
    area: filter.area,
  });

  const { data: alerts, isLoading: isLoadingAlerts } = useAlerts(
    {
      ...topFilter.output,
      area: filter.area,
      page: filter.page,
      limit: filter.limit,
    },
    filter.mil
  );

  const generateParameterCorrectOptions = () => {
    const data = filter.area === Area.PowerPlant ? CORRECTED : NON_PP_CORRECTED;

    return data.map((item: string) => (
      <option value={`--${item}`} key={item}>
        {item} Corrected
      </option>
    ));
  };

  const alertPagination = usePagination({
    count: alerts?.meta.total_data || 0,
    step: filter.limit,
    callback: (page: any) => setFilter({ page }),
    pageTotal: alerts?.meta.last_page,
  });

  const sensors = chartData ? distinctSensor(chartData, filter.area) : [];

  const { data: overviewData, isLoading: isLoadingOverview } = useOverview({
    ...topFilter.output,
    area: filter.area,
  });

  const printToPDF = () =>
    html2Helper.printToPDF({
      idElement: "millsTableWrapper",
      nameFile: `dashboard_${new Date().toLocaleDateString()}.pdf`,
    });

  const handleChangeArea = (value: any) => {
    storeArea(value, () =>
      setFilter({
        mil: "",
        cems: "",
        sensor: "",
        area: value,
      })
    );
  };

  useEffect(() => {
    if (sensors.length) setFilter({ sensor: sensors[0].name });
  }, [sensors.length]);

  useEffect(() => {
    if (currentArea) {
      setFilter({
        ...filter,
        area: currentArea,
      });
    }
  }, [currentArea]);

  useEffect(() => {
    if (user?.area) {
      storeArea(user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant);
      setFilter({
        mil: user?.mil?.uuid ? user?.mil?.uuid : user?.mil,
        cems: user?.cems.length !== 0 ? user?.cems[0].uuid : "",
      });
    }
  }, [user?.area]);

  return (
    <div className="w-100">
      <TopBar title="sispek" subtitle="Dashboard Overview">
        <Button className="me-2" icon={BsFileEarmarkArrowDown} onClick={printToPDF} variant="primary">
          Export
        </Button>
        <TopFilter hook={topFilter} />
        <select
          className="w-auto form-select rounded-pill mx-2"
          onChange={({ currentTarget }) => setFilter({ mil: currentTarget.value, cems: "", sensor: "" })}
          value={filter.mil}
        >
          {!user?.mil && <option value="">All Mills</option>}
          {mills?.map((mil) => (
            <option key={mil.uuid} value={mil.uuid}>
              {mil.name}
            </option>
          ))}
        </select>

        <select
          className="w-auto form-select rounded-pill"
          onChange={({ currentTarget }) => handleChangeArea(currentTarget.value)}
          value={filter.area}
        >
          {Object.values(Area)
            .filter((item) => (user?.area.toLowerCase() === "all" ? item : item === user?.area))
            .map((area) => (
              <option key={area} value={area}>
                {area}
              </option>
            ))}
        </select>
      </TopBar>
      <Container>
        <div className="row align-items-stretch">
          <div className="col-xxl-8 col-12 mb-3">
            {/* All Parameters */}
            <MillsTable overviewData={overviewData} activeMills={filter.mil} isLoading={isLoadingOverview} />
          </div>
          <div className="col-xxl-4 col-4 mb-3">
            <div className="card h-100">
              <div className="card-title">
                <h3>Alert Log</h3>
              </div>
              <div className="card-body overflow-auto d-flex flex-column justify-content-between h-100">
                <table className="table table-striped table-data table-center">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Alert</th>
                    </tr>
                  </thead>
                  <tbody>
                    <RenderIf isTrue={isLoadingAlerts}>
                      <tr>
                        <td className="text-center" colSpan={2}>
                          <Loading />
                        </td>
                      </tr>
                    </RenderIf>
                    <RenderIf isTrue={!isLoadingAlerts}>
                      {alerts?.data.length ? (
                        alerts?.data
                          .filter((alert) => !filter.mil || alert?.mil?.uuid == filter.mil)
                          .map((alert) => (
                            <tr className="small" key={alert.uuid}>
                              <td className={style.no_wrap}>{moment(alert.created_at).format("YYYY-MM-DD HH:mm")}</td>
                              <td>
                                {alert?.cem?.name ? `${alert?.cem?.name}, ` : ""}
                                {alert.description
                                  .replace("sensor name", "")
                                  .replace("lower_threshold", "")
                                  .replace("upper_threshold", "")
                                  .replace("value", "")
                                  .replace(/\d+\.\d{2,}/g, (a) => Number(a).toFixed(2))
                                  .replace(/\,\s*0$/g, "")}
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={2}>
                            No Data
                          </td>
                        </tr>
                      )}
                    </RenderIf>
                  </tbody>
                </table>
                <div className="d-flex justify-content-center">
                  <Pagination hook={alertPagination} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-12 col-8 mb-3">
            <div className="card w-100">
              <div className="card-title">
                <h3 />
                <div class="d-flex flex-row">
                  <select
                    className="w-auto form-select rounded-pill"
                    value={filter.cems}
                    onChange={({ currentTarget }) => setFilter({ cems: currentTarget.value })}
                  >
                    {user?.cems.length === 0 && <option value="">All CEMS</option>}
                    {user?.cems?.length !== 0
                      ? user?.cems?.map((cem) => (
                          <option key={cem.uuid} value={cem.uuid}>
                            {cem.name}
                          </option>
                        ))
                      : cems
                          ?.filter(({ mil }) => mil.uuid == filter.mil || !filter.mil)
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((cem) => (
                            <option key={cem.uuid} value={cem.uuid}>
                              {cem.name}
                            </option>
                          ))}
                  </select>
                  <select
                    value={filter.sensor}
                    onChange={({ currentTarget }) => setFilter({ sensor: currentTarget.value })}
                    className="w-auto form-select rounded-pill ms-2"
                  >
                    {sensors.map((sensor, idx) => (
                      <option key={sensor.name + idx} value={sensor.name}>
                        {sensor.name}
                      </option>
                    ))}
                    {generateParameterCorrectOptions()}
                  </select>
                </div>
              </div>
              <div className="card-body">
                <RenderIf isTrue={!!chartData}>
                  {chartData && (
                    <Chart
                      height={120}
                      sensorName={filter.sensor}
                      cems={filter.cems}
                      mils={filter.mil}
                      chartData={chartData}
                    />
                  )}
                </RenderIf>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DashboardOverviewPage;
