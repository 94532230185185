import L from "leaflet"

const iconMap = new L.Icon({
  iconUrl: "/assets/icons/map.png",
  shadowUrl: "/assets/icons/map-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

export { iconMap }
