import { FunctionComponent, h } from "preact";
import { useState } from "preact/hooks";

import { ApiResponse } from "../../../types/api-response";
import { Button } from "../../../parts/buttons";
import { NCRResponse } from "../../../hooks/useAllNCR";
import TopBar from "../../../components/topbar";
import moment from "moment";
import style from "./style.scss";
import useAuth from "../../../hooks/useAuth";
import useCems from "../../../hooks/useCems";
import { useEffect } from "preact/hooks";
import useMills from "../../../hooks/useMills";
import useObjectState from "../../../hooks/useObjectState";
import { Area } from "../../../enum/area.enum";
import { TailSpin } from "react-loader-spinner";
import RenderIf from "../../../components/RenderIf";

type AddNCRProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  onBack?: () => void;
  uuid?: string;
  editAt?: number;
};

const defaultBody = {
  lk_number: "",
  date: "",
  area: "",
  mil: { uuid: "" },
  cem: { uuid: "" },
  parameter: "",
  condition: "",
  description: "",
  problem: "",
  action: "",
  completion_date_target: "",
  abnormal_type: "",
};

const AddNCR: FunctionComponent<AddNCRProps> = ({ onBack, uuid: ncrId, editAt }) => {
  const { user } = useAuth();
  const [body, setBody] = useObjectState<any>(defaultBody);
  const { data: mills } = useMills();
  const area =
    body.area.length !== 0 ? body.area : user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant;
  const { data: cems } = useCems("", area);
  const { fetcher } = useAuth();
  const [loading, setLoading] = useState(false);
  const [defaultLkNumberAndMil, setDefaultLkNumberAndMil] = useState({
    mil: "",
    lk_number: "",
  });

  useEffect(() => {
    setBody({ date: moment().format("YYYY-MM-DD") });
    fetcher()
      .get<ApiResponse<NCRResponse>>("/ncr/" + ncrId)
      .then(({ data }) => data.data)
      .then((data) => {
        if (data) {
          const {
            lk_number,
            date,
            area,
            cem,
            mil,
            parameter,
            condition,
            description,
            problem,
            action,
            completion_date_target,
          } = data;

          setDefaultLkNumberAndMil({
            mil: mil.uuid,
            lk_number,
          });
          setBody({
            lk_number,
            date,
            area,
            cem: { uuid: cem.uuid },
            mil: { uuid: mil.uuid },
            parameter,
            condition: condition.split(" - ")[0],
            abnormal_type: condition.split(" - ").find((_, idx) => idx == 1),
            description,
            problem,
            action,
            completion_date_target,
          });
        }
      });
  }, [ncrId, editAt]);

  useEffect(() => {
    const mil = mills?.find(({ uuid }) => uuid == body.mil.uuid);
    const milName = mil?.name || "-";
    const date = moment().format("/YYYY/MM/DD");

    if (body?.mil?.uuid !== defaultLkNumberAndMil.mil) {
      mil?.uuid
        ? setLkNumber(
            {
              name: milName,
              uuid: mil.uuid,
            },
            date
          )
        : setBody({ lk_number: "-" });
    } else {
      ncrId && setBody({ lk_number: defaultLkNumberAndMil.lk_number });
    }
  }, [body.mil]);

  const setLkNumber = async (mil: { name: string; uuid: string }, date: string) => {
    setBody({ lk_number: mil.name + date + `/0` });

    fetcher()
      .get(`/ncr/count?date=${moment().format("YYYY-MM-DD")}&mils=${mil.uuid}`)
      .then((res) => {
        setBody({ lk_number: mil.name + date + `/${res?.data?.data?.count + 1}` });
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!ncrId) {
      const { abnormal_type, condition, ...data } = body;
      fetcher()
        .post("/ncr", {
          ...data,
          condition: condition == "Abnormal Condition" ? condition + " - " + abnormal_type : condition,
        })
        .then(() => {
          if (onBack) onBack();
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    } else {
      const { abnormal_type, condition, ...data } = body;
      fetcher()
        .patch("/ncr/" + ncrId, {
          ...data,
          condition: condition == "Abnormal Condition" ? condition + " - " + abnormal_type : condition,
        })
        .then(() => {
          if (onBack) onBack();
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      <TopBar title="SISPEK" subtitle="NCR" onBack={onBack} />
      <div class="p-4">
        <div className="card">
          <div className="card-body h4">Form</div>
          <form
            action=""
            className={style.ncr__details}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="card-body">
              <div className={style.field}>
                <div className={style.label}>LK Number</div>
                <div className={style.value}>{body.lk_number}</div>
              </div>
              <div className={style.field}>
                <div className={style.label}>Date</div>
                <div className={style.value}>{moment(body.date).format("DD MMMM YYYY")}</div>
              </div>
              <div className={style.field}>
                <div className={style.label}>Area</div>
                <select
                  required
                  className="form-control rounded-pill"
                  value={body.area}
                  onChange={({ currentTarget: e }) => setBody({ area: e.value })}
                >
                  <RenderIf isTrue={user?.area.toLowerCase() === "all"}>
                    <option value="Power Plant">Power Plant</option>
                    <option value="Non Power Plant">Non Power Plant</option>
                  </RenderIf>
                  <RenderIf isTrue={user?.area.toLowerCase() !== "all"}>
                    {user?.area === Area.NonPowerPlant ? (
                      <option value="Non Power Plant">Non Power Plant</option>
                    ) : (
                      <option value="Power Plant">Power Plant</option>
                    )}
                  </RenderIf>
                </select>
              </div>
              <div className={style.field}>
                <div className={style.label}>Mills/Unit</div>
                <select
                  required
                  className="form-control rounded-pill"
                  value={body.mil.uuid}
                  onChange={({ currentTarget: e }) => setBody({ mil: { uuid: e.value } })}
                >
                  {mills
                    ?.filter((mil) => {
                      const cems = mil.cems.filter((cem) => (body.area ? cem.area === body.area : !!cem.uuid));

                      return cems.length !== 0;
                    })
                    .map(({ name, uuid }) => (
                      <option value={uuid} key={uuid}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>

              <div className={style.field}>
                <div className={style.label}>CEMS</div>
                <select
                  required
                  className="form-control rounded-pill"
                  value={body.cem.uuid}
                  onChange={({ currentTarget: e }) => setBody({ cem: { uuid: e.value } })}
                >
                  {cems
                    ?.filter(({ mil }) => mil.uuid == body.mil.uuid)
                    ?.map(({ name, uuid }) => (
                      <option value={uuid} key={uuid}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>
              <div className={style.field}>
                <div className={style.label}>Parameter</div>
                <select
                  required
                  className="form-control rounded-pill"
                  value={body.parameter}
                  onChange={({ currentTarget: e }) => setBody({ parameter: e.value })}
                >
                  <option value="All Parameter">All Parameter</option>
                  {cems
                    ?.find(({ uuid }) => uuid == body.cem.uuid)
                    ?.parameters?.map(({ name, uuid }) => (
                      <option value={name} key={uuid}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>

              <div className={style.field}>
                <div className={style.label}>Condition</div>
                <select
                  required
                  className="form-control rounded-pill"
                  value={body.condition}
                  onChange={({ currentTarget: e }) => setBody({ condition: e.value })}
                >
                  <option value="Abnormal Condition">Abnormal Condition</option>
                  <option value="Emergency Condition">Emergency Condition</option>
                </select>
              </div>
              {body.condition == "Abnormal Condition" && (
                <div className={style.field}>
                  <div className={style.label}>Abnormal Condition Type</div>
                  <select
                    required
                    className="form-control rounded-pill"
                    value={body.abnormal_type}
                    onChange={({ currentTarget: e }) => setBody({ abnormal_type: e.value })}
                  >
                    <option value="Maintenance">Maintenance</option>
                    <option value="Calibration">Calibration</option>
                    <option value="Abnormal CEMS">Abnormal CEMS</option>
                    <option value="Abnormal SISPEK">Abnormal SISPEK</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              )}

              <div className={style.field}>
                <div className={style.label}>Description</div>
                <textarea
                  required
                  value={body.description}
                  onChange={({ currentTarget: e }) => setBody({ description: e.value })}
                  className="form-control"
                  style={{ maxWidth: "100%", maxHeight: "560px" }}
                ></textarea>
              </div>

              <div className={style.field}>
                <div className={style.label}>Root Problem Analytics</div>
                <textarea
                  required
                  value={body.problem}
                  onChange={({ currentTarget: e }) => setBody({ problem: e.value })}
                  className="form-control"
                  style={{ maxWidth: "100%", maxHeight: "360px" }}
                ></textarea>
              </div>
              <div className={style.field}>
                <div className={style.label}>Repair Action and Prevention</div>
                <textarea
                  required
                  value={body.action}
                  onChange={({ currentTarget: e }) => setBody({ action: e.value })}
                  className="form-control"
                  style={{ maxWidth: "100%", maxHeight: "360px" }}
                ></textarea>
              </div>
              <div className={style.field}>
                <div className={style.label}>Completion Date Target</div>
                <input
                  value={body.completion_date_target}
                  onChange={({ currentTarget: e }) => setBody({ completion_date_target: e.value })}
                  className="form-control"
                  type="date"
                  required
                ></input>
              </div>
              <div className="d-flex my-3 justify-content-end">
                <Button variant="primary">
                  <RenderIf isTrue={loading}>
                    <TailSpin
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass="d-inline-block me-2"
                    />
                  </RenderIf>
                  {ncrId ? "Save" : "Add Entry"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNCR;
