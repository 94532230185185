export enum Role {
  Admin = "ADMIN",
  Member = "MEMBER",
  Operator = "OPERATOR",
  Manager = "MANAGER",
  HeadDiv = "HEAD_DIV",
  SustainabilityDiv = "SUSTAINABILITY_DIV",
}

export const RoleList: Role[] = [
  Role.Admin,
  Role.Member,
  Role.Operator,
  Role.Manager,
  Role.HeadDiv,
  Role.SustainabilityDiv,
];

export const RoleListWithoutMember: Role[] = [
  Role.Admin,
  Role.Operator,
  Role.Manager,
  Role.HeadDiv,
  Role.SustainabilityDiv,
];

export const RoleListOperatorOnly: Role[] = [Role.Admin, Role.Operator];

export const RoleListManagerAndSustainDiv: Role[] = [Role.Admin, Role.Manager, Role.SustainabilityDiv];

export const RoleListWithoutMemberAndOperator: Role[] = [
  Role.Admin,
  Role.Manager,
  Role.HeadDiv,
  Role.SustainabilityDiv,
];
/**
 * mils one-to-one dis one-to-many das one-to-many sensor
 * filter
 * hourly
 * daily
 */

/**
 * resume roles access
 * Admin    => View Data, Download dan Mengelola Dashboard
 * Member   => View Data, Download
 *
 * Operator => View Data, Download, create NCR
 * Manager  => View, Download, Approve NCR
 * Div Head  => View, Download, Approve NCR
 * Div Sustainability  => View, Download, Validasi NCR
 */
