import { FunctionComponent, h, Fragment } from "preact";

import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { Button } from "../../parts/buttons";
import Container from "../../parts/container";
import Pagination from "../../components/pagination";
import TopBar from "../../components/topbar";
import TopFilter from "../../components/TopFilter";
import { alertsToExcel } from "./toexcel";
import moment from "moment";
import useAlerts from "../../hooks/useAlerts";
import usePagination from "../../hooks/usePagination";
import useTopFilter from "../../hooks/useTopFilter";
import { useEffect, useState } from "preact/hooks";
import useObjectState from "../../hooks/useObjectState";
import useMills from "../../hooks/useMills";
import useCems from "../../hooks/useCems";
import { generateOptionArea } from "./emission";
import useArea from "../../hooks/useArea";
import { Area } from "../../enum/area.enum";
import RenderIf from "../../components/RenderIf";
import Loading from "../../components/loading";
import useAuth from "../../hooks/useAuth";
import { TailSpin } from "react-loader-spinner";
type RecordErrorLogPageProps = h.JSX.HTMLAttributes<HTMLDivElement>;

type FilterType = {
  mil: string;
  cems: string;
  area: any;
  page: number;
  limit: number;
};

const RecordErrorLogPage: FunctionComponent<RecordErrorLogPageProps> = () => {
  const filter = useTopFilter();
  const { user, fetcher } = useAuth();
  const [currentArea, storeArea, removeArea] = useArea(Area.PowerPlant);
  const [selected, setSelected] = useObjectState<FilterType>({
    mil: "",
    cems: "",
    area: user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant,
    page: 1,
    limit: 20,
  });
  const [loading, setLoading] = useState(false);

  const { data: mills } = useMills();
  const { data: cems } = useCems("", selected.area);

  const { data: alerts, isLoading } = useAlerts(
    {
      ...filter.output,
      area: selected.area,
      page: selected.page,
      limit: selected.limit,
    },
    selected.mil,
    selected.cems
  );

  const pageHook = usePagination({
    count: alerts?.meta.total_data || 0,
    step: selected.limit,
    callback: (page: any) => setSelected({ page }),
    pageTotal: alerts?.meta.last_page,
    initPage: alerts?.meta.page && alerts?.meta.page - 1,
  });

  const filteredAlerts = alerts?.data?.filter((alert) => !selected.cems || alert.cem?.uuid == selected.cems);

  const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const handleExport = () => {
    const obj: any = {
      ...filter.output,
      area: selected.area,
      page: 1,
      limit: 1000,
      mils: selected.mil,
    };

    !obj.mils && delete obj.mils;
    if (!selected.mil && selected.cems) {
      obj.cem = selected.cems;
    }

    const queryString = serialize(obj);
    const url = `/alerts/${obj._type}?${queryString}`;

    setLoading((prev) => true);
    fetcher()
      .get(url)
      .then((res: any) => {
        const filteredAlerts = res?.data?.data?.data
          ?.filter((alert: any) => !selected.cems || alert.cem?.uuid == selected.cems)
          .map((item: any) => ({
            ...item,
            created_at: moment(item.created_at).format("DD.MM.YYYY HH:mm"),
          }));

        alertsToExcel(filteredAlerts);
      })
      .finally(() => setLoading((prev) => false));
  };

  const handleChangeArea = (value: any) => {
    storeArea(value, () =>
      setSelected({
        ...filter,
        area: value,
        cems: "",
      })
    );
  };

  useEffect(() => {
    if (currentArea) {
      setSelected({
        ...filter,
        area: currentArea,
      });
    }
  }, [currentArea]);

  useEffect(() => {
    if (user?.area) {
      storeArea(user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant);
      setSelected({
        mil: user?.mil?.uuid ? user?.mil?.uuid : user?.mil,
        cems: user?.cems.length !== 0 ? user?.cems[0].uuid : "",
      });
    }
  }, [user?.area]);

  return (
    <div className="min-vh-100">
      <TopBar title="SISPEK" subtitle="Records Alert Log">
        <TopFilter hook={filter} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title">
            <h3>Alert Log</h3>
          </div>

          <div className="px-4 py-1 d-flex justify-content-between">
            <div className="d-flex mb-3">
              <select
                placeholder="Select Mills"
                value={selected.mil}
                onChange={(e) => setSelected({ ...selected, mil: e.currentTarget.value, cems: "", page: 1 })}
                className="me-2 w-auto form-select rounded-pill"
              >
                {!user?.mil && <option value="">All Mills</option>}
                {mills && mills?.length ? (
                  mills?.map(({ uuid, name }, idx) => {
                    return (
                      <Fragment key={uuid}>
                        <option key={uuid} value={uuid}>
                          {name}
                        </option>
                      </Fragment>
                    );
                  })
                ) : (
                  <option value="">No Mills Data</option>
                )}
              </select>
              <select
                className="me-2 w-auto form-select rounded-pill"
                onChange={({ currentTarget }) => handleChangeArea(currentTarget.value)}
                value={selected.area}
              >
                {Object.values(Area)
                  ?.filter((item) => (user?.area.toLowerCase() === "all" ? item : item === user?.area))
                  .map((area) => (
                    <option key={area} value={area}>
                      {area}
                    </option>
                  ))}
              </select>
              <select
                value={selected.cems}
                onChange={({ currentTarget }) =>
                  setSelected({ cems: currentTarget.value, page: selected?.mil ? selected.page : 1 })
                }
                className="me-2 w-auto form-select rounded-pill"
              >
                {user?.cems.length === 0 && <option value="">Select CEMS</option>}
                {user?.cems?.length !== 0
                  ? user?.cems?.map((cem) => (
                      <option key={cem.uuid} value={cem.uuid}>
                        {cem.name}
                      </option>
                    ))
                  : cems
                      ?.filter(({ mil }) => mil.uuid == selected.mil || !selected.mil)
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      ?.map((cem) => (
                        <option key={cem.uuid} value={cem.uuid}>
                          {cem.name}
                        </option>
                      ))}
              </select>
            </div>
            <div>
              {alerts && (
                <Button variant="primary" onClick={handleExport} icon={BsFileEarmarkArrowDown}>
                  <RenderIf isTrue={loading}>
                    <TailSpin
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass="d-inline-block me-2"
                    />
                  </RenderIf>
                  Export
                </Button>
              )}
            </div>
          </div>
          <div className="card-body">
            <table className="table table-striped table-data table-center">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Mills</th>
                  <th>CEMS</th>
                  <th>Activity</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <RenderIf isTrue={isLoading}>
                  <tr className="text-center">
                    <td colSpan={6} className="text-center">
                      <Loading />
                    </td>
                  </tr>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                  {filteredAlerts?.length ? (
                    filteredAlerts
                      ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                      ?.map((alert, idx) => (
                        <tr key={`alert-${idx}`}>
                          <td>{moment(alert.created_at).format("DD.MM.YYYY")}</td>
                          <td>{moment(alert.created_at).format("HH:mm")}</td>
                          <td>{alert?.mil?.name}</td>
                          <td>{alert?.cem?.name}</td>
                          <td>{alert?.activity}</td>
                          <td>
                            {alert?.description
                              ?.replace("sensor name", `${alert?.cem?.name},` || "")
                              ?.replace("lower_threshold", "")
                              ?.replace("upper_threshold", "")
                              ?.replace("value", "")
                              ?.replace("}", "")
                              ?.replace(/\d+\.\d{2,}/g, (a) => Number(a).toFixed(2))
                              ?.replace(/\,\s*0$/g, "")}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No data.
                      </td>
                    </tr>
                  )}
                </RenderIf>
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <Pagination hook={pageHook} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RecordErrorLogPage;
