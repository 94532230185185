import { ApiResponse } from "../types/api-response";
import useAuth from "./useAuth";
import useSWR from "swr";
import { Cems } from "../types/cems";

type MilsResponse = ApiResponse<
  {
    uuid: string;
    name: string;
    code_cerobong: string;
    coordinate: {
      lat: string;
      long: string;
    };
    tagline: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    cems: Cems[];
  }[]
>;

const useMills = () => {
  const url = "/mil";
  const { fetcher } = useAuth();
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get<MilsResponse>(url)
      .then(({ data }) => data.data.sort((a, b) => a.name.localeCompare(b.name)))
  );
  return {
    data,
    error,
    mutate,
    isLoading: !error && !data,
  };
};

export default useMills;
