import {  h } from "preact"

const Loading = () => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{
      minHeight: 300
    }}>
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
    </div>
  )
}

export default Loading