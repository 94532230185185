import "./style.scss"

import { FunctionComponent, h } from "preact"

type FilterGroupProps = h.JSX.HTMLAttributes<HTMLDivElement>

const FilterGroup: FunctionComponent<FilterGroupProps> = ({ children }) => {
  return <div className="group">{children}</div>
}

export default FilterGroup
