import { FunctionComponent, h } from "preact";
import Router, { Route, route } from "preact-router";
import { useEffect, useState } from "preact/hooks";

import AdminUserPage from "../routes/admin/user";
import AnalyticsPage from "../routes/analytics";
import DashboardMap from "../routes/dashboard/map";
import DashboardOverviewPage from "../routes/dashboard/overview";
import HelpPage from "../routes/help";
import ManualReportPage from "../routes/manual-report";
import NCRKLHKPage from "../routes/ncr/klhk";
import NCRListPage from "../routes/ncr/list";
import Navbar from "./navbar";
import NotificationsPage from "../routes/notifications";
import RecordEmissionPage from "../routes/record/emission";
import RecordErrorLogPage from "../routes/record/error-log";
import RecordPercentagePage from "../routes/record/percentage";
import RecordRawPage from "../routes/record/raw";
import Screen from "../parts/screen";
import TechnicalDataPage from "../routes/technical-data";
import useAuth from "../hooks/useAuth";

type DashboardProps = h.JSX.HTMLAttributes<HTMLDivElement>;

const Dashboard: FunctionComponent<DashboardProps> = () => {
  const { isLogin } = useAuth();
  const [show, setShow] = useState(false);

  const [isCollapse, setCollapse] = useState(false);

  useEffect(() => {
    isLogin()
      .then((logged) => {
        if (logged) return setShow(true);
        return route("/");
      })
      .catch(console.error);
  }, []);

  if (!show) return <div>loading...</div>;
  return (
    <Screen>
      <Navbar collapse={isCollapse} setCollapse={setCollapse} />
      <div
        className="w-100"
        style={{ maxWidth: isCollapse ? "calc(100vw - 58px)" : "calc(100vw - 260px)", transition: "max-width 0.3s" }}
      >
        <Router>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/help" component={HelpPage} />
          <Route path="/dashboard/overview" component={DashboardOverviewPage} />
          <Route path="/dashboard/map" component={DashboardMap} />
          <Route path="/record/raw" component={RecordRawPage} />
          <Route path="/record/emission" component={RecordEmissionPage} />
          <Route path="/record/percentage" component={RecordPercentagePage} />
          <Route path="/record/alert-log" component={RecordErrorLogPage} />
          <Route path="/manual-report" component={ManualReportPage} />
          <Route path="/analytics" component={AnalyticsPage} />
          <Route path="/ncr/list" component={NCRListPage} />
          <Route path="/ncr/klhk" component={NCRKLHKPage} />
          <Route path="/technical-data" component={TechnicalDataPage} />
          <Route path="/admin/user" component={AdminUserPage} />
          <Route path="/notifications" component={NotificationsPage} />
        </Router>
      </div>
    </Screen>
  );
};

export default Dashboard;
