import { BsPencil, BsTrash } from "react-icons/bs";
import { FunctionComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";

import AddKLHK from "./add-klhk";
import { BiMapPin } from "react-icons/bi";
import { Button } from "../../../parts/buttons";
import Container from "../../../parts/container";
import { Role, RoleListOperatorOnly, RoleListWithoutMemberAndOperator } from "../../../enum/roles.enum";
import TopBar from "../../../components/topbar";
import TopFilter from "../../../components/TopFilter";
import useAllKlhk from "../../../hooks/useAllklhk";
import useAuth from "../../../hooks/useAuth";
import { useConfirm } from "../../../context/useConfirm";
import useMills from "../../../hooks/useMills";
import useModal from "../../../hooks/useModal";
import useObjectState from "../../../hooks/useObjectState";
import useTopFilter from "../../../hooks/useTopFilter";
import { roleCheck } from "../../../utils/permissions";
import RenderIf from "../../../components/RenderIf";
import useProfile from "../../../hooks/useProfile";
import Loading from "../../../components/loading";
import usePagination from "../../../hooks/usePagination";
import Pagination from "../../../components/pagination";

type NCRKLHKPageProps = h.JSX.HTMLAttributes<HTMLDivElement>;

const NCRKLHKPage: FunctionComponent<NCRKLHKPageProps> = () => {
  const [refreshNotif, setRefreshNotif] = useState<boolean>(false);
  const filter = useTopFilter();

  const { user, isLogin } = useAuth();
  const { data: mills } = useMills();
  const [_, setMe] = useState<Role>(Role.Member);
  const confirmation = useConfirm();
  const { fetcher } = useAuth();
  const [selected, setSelected] = useObjectState({
    mil: "",
    isEdit: false,
    uuid: "",
    modal_status: "",
    edit_at: 0,
  });

  const [pagination, setPagination] = useObjectState({
    page: 1,
    limit: 10,
  });

  const {
    data: klhk,
    mutate,
    isLoading,
  } = useAllKlhk({
    ...filter.output,
    page: pagination.page,
    limit: pagination.limit,
  });

  const pageHook = usePagination({
    count: klhk?.meta.total_data || 0,
    step: pagination.limit,
    callback: (page: any) => setPagination({ page }),
    pageTotal: klhk?.meta.last_page,
  });

  const { onHide, onShow, show } = useModal();
  const userProfile = useProfile();

  const handleRefreshNotif = () => {
    setRefreshNotif((prev: boolean) => !prev);

    setTimeout(() => {
      setRefreshNotif((prev: boolean) => !prev);
    }, 500);
  };

  useEffect(() => {
    isLogin().then((me) => {
      if (me) setMe(me.role);
    });
  }, [isLogin]);

  const onBack = () => {
    setSelected({ isEdit: false });
    mutate();
    handleRefreshNotif();
  };

  const handleDeleteKlhk = (id: string) => {
    confirmation(
      "Are you sure you want to delete it?",
      (isDel) => {
        if (isDel)
          fetcher()
            .delete("/ncr-klhk/" + id)
            .then(() => {
              onBack();
            });
      },
      { yes: "Delete", no: "Cancel" }
    );
  };
  const handleUpdate = () => {
    onHide();
    if (selected.modal_status == "Closed")
      fetcher()
        .put("/ncr-klhk/validation/" + selected.uuid)
        .then(() => {
          onBack();
        });
  };

  useEffect(() => {
    if (user?.area) {
      setSelected({ mil: user?.mil?.uuid ? user?.mil?.uuid : user?.mil });
    }
  }, [user?.area]);
  if (selected.isEdit) return <AddKLHK uuid={selected.uuid} editAt={selected.edit_at} onBack={onBack} />;
  return (
    <div>
      {/* modal */}
      <div
        className="modal-backdrop"
        style={{
          display: show ? "inherit" : "none",
          backgroundColor: "#00000099",
          overflowY: "auto",
        }}
      >
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <div class="modal-content card">
            <div className="card-body text-center h5">Report Status</div>
            <div className="card-body">
              <select
                className="form-control rounded-pill"
                onChange={({ currentTarget: e }) => setSelected({ modal_status: e.value })}
                value={selected.modal_status}
              >
                <option value="Open">OPEN</option>
                <option value="Closed">CLOSE</option>
              </select>
            </div>
            <div className="card-body d-flex justify-content-center">
              <Button onClick={onHide} variant="tertiary" className="m-1">
                Cancel
              </Button>
              <Button onClick={handleUpdate} variant="primary" className="m-1">
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
      <TopBar title="SISPEK" subtitle="KLHK" refreshNotif={refreshNotif}>
        <TopFilter hook={filter} />
        <select
          value={selected.mil}
          onChange={({ currentTarget: e }) => setSelected({ mil: e.value })}
          className="w-auto form-select rounded-pill"
          id=""
        >
          {!user?.mil && <option value="">All Mills</option>}
          {mills?.map((mil) => (
            <option value={mil?.uuid} key={mil?.uuid}>
              {mil?.name}
            </option>
          ))}
        </select>
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between align-items-end">
              <div className="">
                <h3>KLHK</h3>
              </div>
              <div className="">
                <RenderIf
                  isTrue={roleCheck({
                    roles: RoleListOperatorOnly,
                    currentRole: userProfile?.role,
                  })}
                >
                  <Button variant="primary" onClick={() => setSelected({ isEdit: true })} className="ms-2">
                    ADD NEW REPORT
                  </Button>
                </RenderIf>
              </div>
            </div>
          </div>
          <div className="card-body">
            <table className="table table-striped table-center">
              <thead>
                <tr>
                  <th>Mills</th>
                  <th>CEMS</th>
                  <th>Problem</th>
                  <th>Refinement Date</th>
                  <th>Status</th>
                  <th>Menu</th>
                </tr>
              </thead>
              <tbody>
                <RenderIf isTrue={isLoading}>
                  <tr>
                    <td colSpan={6}>
                      <Loading />
                    </td>
                  </tr>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                  {klhk && klhk?.data?.length !== 0 ? (
                    klhk?.data
                      ?.filter(({ mil }) => mil?.uuid == selected.mil || !selected.mil)
                      .sort((a: any, b: any) => new Date(b?.ncr_klhk_status_history[0]?.created_at).getTime() - new Date(a?.ncr_klhk_status_history[0]?.created_at).getTime())
                      .map((val) => (
                        <tr key={val?.uuid}>
                          <td>{val?.mil?.name}</td>
                          <td>{val?.cem?.name || '-'}</td>
                          <td>{val?.description}</td>
                          <td>{val?.completion_date}</td>
                          <td>{val?.status}</td>
                          <td>
                            <Button
                              className="me-2"
                              variant="secondary"
                              icon={BsPencil}
                              onClick={() => {
                                setSelected({
                                  uuid: val?.uuid,
                                  isEdit: true,
                                  edit_at: new Date().getTime(),
                                });
                              }}
                            />
                            <Button
                              className="me-2"
                              variant="tertiary"
                              onClick={() => handleDeleteKlhk(val?.uuid)}
                              icon={BsTrash}
                            />
                            {val?.status !== "Closed" && (
                              <Button
                                className="me-2"
                                variant="secondary"
                                onClick={() => {
                                  setSelected({
                                    uuid: val?.uuid,
                                    modal_status: val?.status,
                                  });
                                  onShow();
                                }}
                                icon={BiMapPin}
                              />
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={6}>No Data</td>
                    </tr>
                  )}
                </RenderIf>
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <Pagination hook={pageHook} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NCRKLHKPage;
