import { Fragment, FunctionComponent, h } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"

import { OverviewDataResponse } from "../../hooks/useOverview"
import { paramsSort } from "../../utils/distinctSensor"
import Loading from "../loading"
import style from "./style.scss"

type MillsTableProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  overviewData?: OverviewDataResponse;
  activeMills?: string;
  isLoading?: boolean;
}

const MillsTable: FunctionComponent<MillsTableProps> = ({ overviewData: companies, activeMills, isLoading = false }) => {

  const [showHeaderOnScroll, setShowHeaderOnScroll] = useState(false)
  const millsTableRef = useRef<any>(null)

  const toggleMillsHeader = (element: any) => setShowHeaderOnScroll(element.scrollTop > 106);

  useEffect(() => {

    if (millsTableRef.current) {
      millsTableRef.current.addEventListener("scroll", () => toggleMillsHeader(millsTableRef.current));
    }

    return (): void => {
      millsTableRef.current.removeEventListener("scroll", () => toggleMillsHeader(millsTableRef.current))
    }
  }, [])

  return (
    <div className="card">
      <div className="card-title">
        <h3>All Parameters</h3>
      </div>
      <div className="card-body position-relative" >
        <div className={style.mills__wrapper} id="millsTable" ref={millsTableRef}>
          {isLoading ? (
            <Loading />
          ):(
<Fragment>
            {companies
              ?.filter(({ mils }) => mils?.filter(({ uuid }) => !activeMills || uuid == activeMills).length)
              .map((data, index) => (
                <div className={style.mills} key={index} id="millsTableWrapper">
                  <div className={style.title}>{data.name}</div>
                  <div className={style.card} id="heading-table">
                    <div className={`${style.heading} ${showHeaderOnScroll ?style.heading__show : ''}`}>
                      <div className={style.item}>LAST</div>
                      <div className={style.item}>AVG</div>
                      <div className={style.item}>MAX</div>
                      <div className={style.item}>MIN</div>
                      <div className={style.separator} />
                      <div className={style.item}>Valid Data (%)</div>
                      <div className={style.item}>Invalid</div>
                      <div className={style.separator} />
                      <div className={style.item}>Data Comply (%)</div>
                      <div className={style.item}>Incomply</div>
                      <div className={style.separator} />
                      <div className={style.item}>NCR</div>
                    </div>
                    {data?.mils
                      ?.filter(({ uuid }) => !activeMills || uuid == activeMills)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(({ name, cems }, idx) => (
                        <div key={idx} className={style.mills}>
                          <div className={style.name}>{name}</div>
                          <div className={style.cems}>
                            {cems?.map(({ name, parameters }, idx) => {
                              const sensor = parameters
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .sort((a, b) => paramsSort(a.name, b.name))
                              return (
                                <div key={idx} className={style.cem}>
                                  <div className={style.name}>{name}</div>
                                  <div className={style.params}>
                                    {sensor?.map(({ name, uom }, idx) => (
                                      <div className="" key={idx}>
                                        <strong className="me-1">{name}</strong>
                                        <span className={style.uom}>({uom})</span>
                                      </div>
                                    ))}
                                  </div>
                                  {/* last avg max min*/}
                                  <div className={style.last}>
                                    {sensor?.map(({ last }, idx) => (
                                      <div className="" key={idx}>
                                        {last?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                  <div className={style.avg}>
                                    {sensor?.map(({ avg }, idx) => (
                                      <div className="" key={idx}>
                                        {avg?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                  <div className={style.max}>
                                    {sensor?.map(({ max }, idx) => (
                                      <div className="" key={idx}>
                                        {max?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                  <div className={style.min}>
                                    {sensor?.map(({ min }, idx) => (
                                      <div className="" key={idx}>
                                        {min?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                  <div className={style.separator} />

                                  {/* data valid */}
                                  <div className={style.valid_percent}>
                                    {sensor?.map(({ data_valid }, idx) => (
                                      <div className="" key={idx}>
                                        {data_valid.percentage?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                  <div className={style.invalid}>
                                    {sensor?.map(({ data_invalid }, idx) => (
                                      <div className="" key={idx}>
                                        {data_invalid?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                  <div className={style.separator} />

                                  {/* data complay */}
                                  <div className={style.comply_percent}>
                                    {sensor?.map(({ data_comply }, idx) => (
                                      <div className="" key={idx}>
                                        {data_comply.percentage?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                  {/* <div className={style.comply_amt}>
                                    {sensor?.map(({ data_comply }, idx) => (
                                      <div className="" key={idx}>
                                        {data_comply.amount?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div> */}
                                  <div className={style.incomply}>
                                    {sensor?.map(({ data_incomply }, idx) => (
                                      <div className="" key={idx}>
                                        {data_incomply?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                  <div className={style.separator} />

                                  {/* ncr */}
                                  <div className={style.ncr}>
                                    {sensor?.map(({ ncr }, idx) => (
                                      <div className="" key={idx}>
                                        {ncr?.toFixed(0)}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </Fragment>
          )}
          
          
        </div>
      </div>
    </div>
   
  )
}

export default MillsTable
