import { Area } from "../enum/area.enum";
import { RawDataResponse } from "../hooks/useRaw";

const paramsOrder = [
  "Particulate",
  "SO2",
  "NOx",
  "Hg",
  "O2",
  "Opacity",
  "CO",
  "CO2",
  "Flow",
  "Velocity",
  "Pressure",
  "Temperature",
].reverse();

const paramsOrderNonPP = ["TRS", "Cl2", "ClO2", "Particulate", "Opacity", "Oksigen"].reverse();

export const CORRECTED = ["Particulate", "Hg", "SO2", "NOx"];
export const NON_PP_CORRECTED = ["Particulate", "ClO2", "Cl2", "TRS"];
export const checkCorrected = (name: string, area: Area) =>
  area === Area.PowerPlant ? CORRECTED.includes(name) : NON_PP_CORRECTED.includes(name);

const clearWhitespace = (text: string) => text.replace(/\s/g, "");

const distinctSensor = (data: RawDataResponse, area?: string, currentCem?: string) =>
  data
    ?.map((company) =>
      company.mils
        ?.map((mils) =>
          mils.cems
            ?.filter((val) => (area ? val.area === area : !!val.uuid))
            ?.filter((cem) => (currentCem ? cem.uuid === currentCem : !!cem.uuid))
            ?.map((cem) => cem.parameters)
            ?.reduce((a, b) => a.concat(b), [])
        )
        ?.reduce((a, b) => a.concat(b), [])
    )
    ?.reduce((a, b) => a.concat(b), [])
    ?.filter((value, index, self) => {
      return self.map(({ name }) => clearWhitespace(name)).indexOf(clearWhitespace(value.name)) === index;
    })

    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) =>
      area
        ? area === Area.PowerPlant
          ? paramsSort(a.name, b.name)
          : paramsSortNonPP(a.name, b.name)
        : paramsSort(a.name, b.name)
    );

export const paramsSort = (a: string, b: string) => paramsOrder.indexOf(b) - paramsOrder.indexOf(a);

const getParamNonPP = (param: string) => (param.indexOf(" ") !== -1 ? param.substring(0, param.indexOf(" ")) : param);

export const paramsSortNonPP = (a: string, b: string) =>
  paramsOrderNonPP.indexOf(getParamNonPP(a)) < paramsOrderNonPP.indexOf(getParamNonPP(b)) ? 1 : -1;

export default distinctSensor;
