import { BiCopy, BiData } from "react-icons/bi"
import { FunctionComponent, h } from "preact"

import { BsFillTriangleFill } from "react-icons/bs"
import { Link } from "preact-router/match"
import style from "./index.scss"
import { useState, useEffect } from "preact/hooks"
import useAuth from "../../hooks/useAuth"

type NavbarItemProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  item: {
    label: string
    link?: string
    icon?: any,
    sub: {
      label: string
      link: string
      icon?: any,
    }[]
  }
}

const Image = ({icon, label}:{icon: string, label: string}) => <img 
  src={icon} 
  alt={label}
  width={24}
  height={24}
  />

const NavbarItem: FunctionComponent<NavbarItemProps> = ({ item }) => {
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow((n) => !n)
  }
  // const { isLogin } = useAuth()
  // useEffect(() => {
  //   isLogin()
  //     .then((logged) => {
  //       // if (logged.role)
  //       console.log(logged)
  //     })
  //     .catch(console.error)
  // }, [])
  return (
    <div className={style.item}>
      {item.sub.length ? (
        <div>
          <div className={style.label} onClick={toggle}>
            <div className="d-flex w-100 align-items-center">
              <div className={style.icon}>
                {item?.icon ? <Image icon={item?.icon} label={item.label} /> : <BiData />}
              </div>
              <span className="me-auto">{item.label}</span>
              <span className={style.triangle + " " + (show ? style.show : "")}>
                <BsFillTriangleFill />
              </span>
            </div>
          </div>
          <div className={show ? style.show : style.hide}>
            {item.sub.map((sub) => (
              <Link
                href={sub.link}
                class={style.sub}
                activeClassName={style.active}
                key={sub.label}
              >
                <div className={style.icon}>
                  {sub?.icon ? <Image icon={sub?.icon} label={sub.label} /> : <BiCopy />}
                </div>
                <span>{sub.label}</span>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <Link
          href={item.link}
          class={style.label}
          activeClassName={style.active}
        >
          <div className={style.icon}>
            {item?.icon ? <Image icon={item?.icon} label={item.label} /> : <BiData />}
          </div>
          <span>{item.label}</span>
        </Link>
      )}
    </div>
  )
}

export default NavbarItem
