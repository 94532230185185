import { useEffect, useState } from "preact/hooks";
import { AREA as CONST_AREA } from "../constants";
import { Area } from "../enum/area.enum";
import { localStorageHelper } from "../utils/localStorage";
const useArea = (defaultArea: Area | string = Area.PowerPlant): any => {
  const [currentArea, setCurrentArea] = useState<Area | string>(Area.PowerPlant);

  const store = (area: Area | string, callback?: () => void) => {
    localStorageHelper.store(CONST_AREA, area);
    setCurrentArea(area);
    callback && callback();
  };

  const remove = () => localStorageHelper.delete(CONST_AREA);

  useEffect(() => {
    const tempArea = localStorageHelper.load(CONST_AREA);

    if (!tempArea) {
      localStorageHelper.store(CONST_AREA, defaultArea);
      setCurrentArea(defaultArea);
      return;
    }

    setCurrentArea(tempArea);
  }, []);

  return [currentArea, store, remove];
};

export default useArea;
