import { Fragment, FunctionComponent, h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { Role, RoleList } from "../../../enum/roles.enum";

import { AxiosApiError } from "../../../types/api-response";
import { Button } from "../../../parts/buttons";
import { mutate } from "swr";
import useAllCompany, { AllCompanyResponse } from "../../../hooks/useAllCompany";
import useAuth from "../../../hooks/useAuth";
import useObjectState from "../../../hooks/useObjectState";
import useUsers from "../../../hooks/useUsers";
import { Area } from "../../../enum/area.enum";
import RenderIf from "../../../components/RenderIf";
import { MultiSelect } from "react-multi-select-component";
import { Cems } from "../../../types/cems";

type NewUserProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean;
  onHide: () => void;
};

export type MultiSelectItem = {
  value: string;
  label: string;
};

export interface DefaultStateInterface {
  name: string;
  email: string;
  password?: string;
  role: any;
  company: { uuid: string };
  mil: { uuid: string };
  notification: boolean;
  notification_interval: number;
  area: string;
  cems: string;
}

const NewUser: FunctionComponent<NewUserProps> = ({ show, onHide }) => {
  const [cems, setCems] = useState<any>([]);
  const [cemsSelected, setCemsSelected] = useState<MultiSelectItem[]>([]);

  const { data: companies } = useAllCompany();
  const defaultState = {
    name: "",
    email: "",
    password: "",
    role: Role.Operator,
    company: { uuid: "" },
    mil: { uuid: "" },
    notification: false,
    notification_interval: 6,
    area: "All",
    cems: "All",
  };
  const [user, setUser] = useObjectState<DefaultStateInterface>(defaultState);
  const { fetcher } = useAuth();

  const handleErrorResponse = (e: AxiosApiError) => {
    const msg = e.response?.data?.data?.message || e.response?.data?.message || "Server Error";
    if (alert) {
      if (Array.isArray(msg)) alert(msg.map((t) => "- " + t).join("\n"));
      else alert(msg);
    }
  };

  const generateCemsOption = (companies?: AllCompanyResponse[]) => {
    const mil = companies
      ?.find(({ uuid }) => uuid == user.company.uuid)
      ?.mils?.filter((mil) => mil.uuid === user.mil.uuid);

    return (
      mil &&
      mil[0]?.cems
        .filter((cem) => cem.area === user.area)
        .map(({ uuid, name }) => (
          <option key={uuid} value={uuid}>
            {name}
          </option>
        ))
    );
  };

  const generateCemsCheckbox = (companies?: AllCompanyResponse[]) => {
    const mil = companies
      ?.find(({ uuid }) => uuid == user.company.uuid)
      ?.mils?.filter((mil) => (user.mil.uuid ? mil.uuid === user.mil.uuid : !!mil.uuid));

    let cems: any = [];
    mil?.forEach((mil) => {
      const newCems = mil?.cems
        .filter((cem) => (user.area == "All" ? !!cem.uuid : cem.area === user.area))
        .map(({ uuid, name }) => ({ uuid, name }));

      cems = [...cems, ...newCems];
    });

    const newCems = cems.map((item: any) => ({
      value: item.uuid,
      label: item.name,
    }));

    // setCems(newCems);

    return cems?.map(({ uuid, name }: any) => (
      <div className="col-6 mb-2" key={uuid}>
        <input type="checkbox" id={uuid} name="cems" value={uuid} />
        <label for={uuid} className="ms-2">
          {name}
        </label>
      </div>
    ));
  };

  const getAllCems = (role: string): any[] => {
    if (role === Role.Operator || role === Role.Member) {
      let tempCems: any = null;

      if (user.mil.uuid) {
        const mil = companies
          ?.find(({ uuid }) => uuid == user.company.uuid)
          ?.mils?.filter((mil) => mil.uuid === user.mil.uuid);

        if (mil) {
          tempCems = user.area === "All" ? mil[0]?.cems : mil[0]?.cems.filter((cem) => cem.area === user.area);
        }
      } else {
        tempCems = [];
      }

      return tempCems;
    }

    return [];
  };

  const getOneCem = (companies?: AllCompanyResponse[], uuid?: string): any => {
    const mil = companies
      ?.find(({ uuid }) => uuid == user.company.uuid)
      ?.mils?.filter((mil) => mil.uuid === user.mil.uuid);

    return mil && mil[0]?.cems.filter((cem) => cem.area === user.area && cem.uuid === uuid);
  };

  const getCems = (companies?: AllCompanyResponse[], cems?: MultiSelectItem[]) => {
    const tempCems: any[] = [];
    cems?.forEach((item: MultiSelectItem) => {
      companies
        ?.find(({ uuid }) => uuid == user.company.uuid)
        ?.mils?.forEach((mil) => {
          mil.cems.forEach((cem) => {
            if (cem.uuid === item?.value) {
              const newCem: any = {
                ...cem,
                parameters: cem.parameters,
              };

              delete newCem.parameters;

              tempCems.push(newCem);
            }
          });
        });
    });

    return tempCems;
  };

  const handleSubmit = () => {
    const formData: any = {
      name: user.name,
      email: user.email,
      password: user.password,
      role: user.role,
      company: user.company,
      mil: user.mil,
      notification: user.notification,
      notification_interval: user.notification_interval,
      area: user.area,
      cems: [],
    };
    if ((user.role == Role.Admin && user.company.uuid == "") || (user.role == Role.Member && user.company.uuid == "")) {
      const { name, email, password, role, notification, notification_interval, area, cems } = formData;

      fetcher()
        .post("/auth/sign-up", {
          name,
          email,
          password,
          role,
          notification,
          notification_interval,
          area,
          cems: null,
        })
        .then((res) => {
          if (onHide) onHide();
          setUser(defaultState);
          mutate("/user");
          return res.data;
        })
        .then((res) => {
          if (!res?.data?.access_token) return;
          const me = JSON.parse(atob(res?.data?.access_token?.split(".")[1])).uuid;
          if (!me) return;
          fetcher()
            .patch("/user/" + me, {
              notification: user.notification,
              notification_interval: user.notification_interval,
            })
            .then(() => {
              mutate("/user");
            })
            .catch(console.log);
        })
        .catch(handleErrorResponse);
    } else {
      if (user.mil.uuid === "") {
        formData.mil = null;
      }

      formData.cems = getCems(companies, cemsSelected);

      fetcher()
        .post("/user", formData)
        .then(() => {
          if (onHide) onHide();
          setUser(defaultState);
          mutate("/user");
        })
        .catch(handleErrorResponse);
    }
  };
  const handleCancel = () => {
    setUser(defaultState);
    if (onHide) onHide();
  };

  useEffect(() => {
    setCems(() => []);
    setCemsSelected([]);

    const mil = companies
      ?.find(({ uuid }) => uuid == user.company.uuid)
      ?.mils?.filter((mil) => (user.mil.uuid ? mil.uuid === user.mil.uuid : !!mil.uuid));

    let cems: any = [];
    mil?.forEach((mil) => {
      const newCems = mil?.cems
        .filter((cem) => (user.area == "All" ? !!cem.uuid : cem.area === user.area))
        .map(({ uuid, name }) => ({ uuid, name }));

      cems = [...cems, ...newCems];
    });

    const newCems = cems.map((item: any) => ({
      value: item.uuid,
      label: item.name,
    }));

    setCems(() => newCems);
  }, [user.company.uuid, user.mil.uuid, user.area, user.role]);

  return (
    <div
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
    >
      <div class="modal-dialog modal-lg modal-dialog-centered " role="document">
        <div class="modal-content card">
          <div className="card-body d-flex justify-content-between">
            <h5 class="card-title">Add New User</h5>
            <div>
              <Button variant="primary" onClick={handleSubmit}>
                Add User
              </Button>
              <Button variant="tertiary" className="ms-2" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
          <div className="card-body px-4">
            <div class="form-group pb-2">
              <label>Name</label>
              <input
                value={user.name}
                required
                type="text"
                class="form-control"
                onChange={({ currentTarget }) => {
                  setUser({ name: currentTarget.value });
                }}
              />
            </div>
            <div class="form-group pb-2 ">
              <label>Email</label>
              <input
                value={user.email}
                required
                type="email"
                class="form-control"
                onChange={({ currentTarget }) => {
                  setUser({ email: currentTarget.value });
                }}
              />
            </div>
            <div class="form-group pb-2 ">
              <label>Password</label>
              <input
                value={user.password}
                required
                type="string"
                class="form-control"
                onChange={({ currentTarget }) => {
                  setUser({ password: currentTarget.value });
                }}
              />
            </div>
            <div class="form-group pb-2">
              <label>Role</label>
              <select
                name=""
                value={user.role}
                className="rounded-pill form-control"
                onChange={({ currentTarget }) => {
                  setUser({ role: currentTarget.value as Role });
                }}
                id=""
              >
                {RoleList.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
            <div class="form-group pb-2">
              <label>Area</label>
              <select
                name=""
                value={user.area}
                className="rounded-pill form-control"
                onChange={({ currentTarget: e }) => setUser({ area: e.value })}
                id=""
              >
                <option value="All">All Area</option>
                {Object.values(Area).map((area) => (
                  <option key={area} value={area}>
                    {area}
                  </option>
                ))}
              </select>
            </div>
            <div class="form-group pb-2">
              <label>Affiliate</label>
              <select
                name=""
                className="rounded-pill form-control"
                value={user.company.uuid}
                id=""
                onChange={({ currentTarget }) => {
                  setUser({ company: { uuid: currentTarget.value } });
                }}
              >
                {(user.role == Role.Admin || user.role == Role.Member) && <option value="">HQ</option>}
                {companies?.map(({ uuid, name }) => (
                  <option key={uuid} value={uuid}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            {user.company.uuid && (
              <div class="form-group pb-2">
                <label>Mills</label>
                <select
                  name=""
                  className="rounded-pill form-control"
                  value={user.mil.uuid}
                  id=""
                  onChange={({ currentTarget }) => {
                    setUser({ mil: { uuid: currentTarget.value } });
                  }}
                >
                  <RenderIf isTrue={user.role !== Role.Member && user.role !== Role.Operator}>
                    <option value="">All Mills</option>
                  </RenderIf>
                  {companies
                    ?.find(({ uuid }) => uuid == user.company.uuid)
                    ?.mils?.map(({ uuid, name }) => (
                      <option key={uuid} value={uuid}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>
            )}

            <RenderIf isTrue={!!user.company.uuid}>
              <div class="form-group pb-2">
                <label>CEMS</label>
                <div
                  className="w-100"
                  style={{
                    maxWidth: 400,
                  }}
                >
                  <MultiSelect options={cems} value={cemsSelected} onChange={setCemsSelected} labelledBy="Select" />
                </div>
              </div>
            </RenderIf>

            <div class="form-group pb-2">
              <label>Notification</label>
              <select
                name=""
                className="rounded-pill form-control"
                value={user.notification ? "on" : "off"}
                id=""
                onChange={({ currentTarget }) => {
                  setUser({ notification: currentTarget.value == "on" });
                }}
              >
                <option value="on">On</option>
                <option value="off">Off</option>
              </select>
            </div>
            <div class="form-group pb-2 ">
              <label>Email Notification Interval</label>
              {/* <input value={user.notification_interval + " Hours"} required type="text" disabled class="form-control" /> */}
              <input value={1 + " Hours"} required type="text" disabled class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
