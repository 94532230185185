import { useState, useEffect } from "preact/hooks";

export type PaginationData = {
  page: number;
  setPage: (n: number) => void;
  output: number;
  step: number;
  count: number;
  maxPage: number;
  isFromServer?: boolean;
  next: () => void;
  prev: () => void;
};

type PaginationProps = {
  count: number;
  step: number;
  callback?: (page: any) => void;
  pageTotal?: number;
  initPage?: number;
};
const usePagination = ({ count, step, callback, pageTotal, initPage }: PaginationProps): PaginationData => {
  const [page, changePage] = useState(0);
  const maxPage = pageTotal ? pageTotal : Math.floor(count / step);
  const isFromServer = !!pageTotal;

  const setPage = (n: number) => {
    if (n > maxPage) {
      changePage(maxPage);
      callback && callback(maxPage);
    } else {
      if (n < 0) {
        changePage(0);
        callback && callback(1);
      } else {
        changePage(n);
        callback && callback(n === pageTotal ? pageTotal : n + 1);
      }
    }
  };

  const next = () => setPage(page + 1);
  const prev = () => setPage(page - 1);

  useEffect(() => {
    changePage(initPage || 0);
  }, [initPage]);

  return {
    page,
    setPage,
    step,
    count,
    maxPage,
    next,
    prev,
    output: page * step,
    isFromServer,
  };
};

export default usePagination;
