import { FunctionComponent, h } from "preact";
import distinctSensor, { checkCorrected, CORRECTED, NON_PP_CORRECTED } from "../../utils/distinctSensor";
import useRaw, { PayloadPercentage } from "../../hooks/useRaw";

import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { Button } from "../../parts/buttons";
import Container from "../../parts/container";
import Pagination from "../../components/pagination";
import { SensorTypeQuery } from "../../enum/sensor.enum";
import TopBar from "../../components/topbar";
import TopFilter from "../../components/TopFilter";
import moment from "moment";
import { rawToExcel } from "./toexcel";
import useCems from "../../hooks/useCems";
import useMills from "../../hooks/useMills";
import useObjectState from "../../hooks/useObjectState";
import usePagination from "../../hooks/usePagination";
import useTopFilter from "../../hooks/useTopFilter";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { Area } from "../../enum/area.enum";
import { generateOptionArea } from "./emission";
import useArea from "../../hooks/useArea";
import { useEffect, useMemo } from "preact/hooks";
import RenderIf from "../../components/RenderIf";
import Loading from "../../components/loading";
import useAuth from "../../hooks/useAuth";
type RecordRawPageProps = h.JSX.HTMLAttributes<HTMLDivElement>;

const RecordRawPage: FunctionComponent<RecordRawPageProps> = () => {
  const { promiseInProgress } = usePromiseTracker();
  const { user } = useAuth();
  const [currentArea, storeArea, removeArea] = useArea(Area.PowerPlant);

  const topFilter = useTopFilter(true);
  const [filter, setFilter] = useObjectState({
    mil: "",
    cems: "",
    area: user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant,
  });

  const { data: mills } = useMills();
  const { data: cems } = useCems("", filter.area);
  const { data, isLoading, mutate } = useRaw({
    mils: filter.mil,
    type: SensorTypeQuery.raw,
    filter: topFilter.output,
    area: filter.area,
  });
  // const [isLoaded, setIsLoaded] = useState(false);
  const columns = data ? distinctSensor(data, filter.area) : [];
  // useEffect(() => {
  // const timer = setTimeout(() => console.log('Initial timeout!'), 1000);
  // if (!timer){
  //   alert("Error : Too Much Data Load")
  //   clearTimeout(timer);
  // }

  const tempQuery = topFilter.output as any;

  const valueFilterExpensive = useMemo(() => tempQuery?.value, [tempQuery?.value]);

  const handleChangeArea = (value: any) => {
    storeArea(value, () =>
      setFilter({
        ...filter,
        area: value,
      })
    );
  };

  useEffect(() => {
    if (currentArea) {
      setFilter({
        ...filter,
        area: currentArea,
      });
    }
  }, [currentArea]);

  useEffect(() => {
    // console.log("expensive", valueFilterExpensive);
    let interval: any = null;

    if (valueFilterExpensive === 6) {
      interval = setInterval(() => {
        mutate();
      }, 5 * 60 * 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [valueFilterExpensive]);

  useEffect(() => {
    if (user?.area) {
      storeArea(user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant);
      setFilter({
        mil: user?.mil?.uuid ? user?.mil?.uuid : user?.mil,
        cems: user?.cems.length !== 0 ? user?.cems[0].uuid : "",
      });
    }
  }, [user?.area]);

  useEffect(() => {
    if (!user?.mil && mills?.length !== 0) {
      setFilter({
        mil: mills && mills[0].uuid,
      });
    }
  }, [mills, user?.mil]);

  const tableData = data
    ?.map((comp) =>
      comp.mils
        .filter(({ uuid }) => !filter.mil || uuid == filter.mil)
        .map(({ cems }) =>
          cems
            .filter(({ uuid }) => !filter.cems || uuid == filter.cems)
            .map((cem) =>
              cem.payloads?.map((payload) => {
                const values = columns.map((col) => {
                  const data = payload.payloads.find(({ name }) => name == col.name) as PayloadPercentage;
                  return [data?.value, data?.value_condition_override] as [number, number];
                });
                const params = columns.map(
                  ({ name }) => cem.parameters.find(({ name: id }) => id == name)?.upper_threshold
                );
                return {
                  cemsId: cem.uuid,
                  cems: cem.name,
                  date: payload.measured_at,
                  values,
                  params,
                };
              })
            )
            .reduce((a, b) => (b ? a?.concat(b) : a), [])
        )
        .reduce((a, b) => (b ? a?.concat(b) : a), [])
    )
    .reduce((a, b) => (b ? a?.concat(b) : a), [])
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const pages = usePagination({ count: tableData?.length || 0, step: 20 });
  const showTable = tableData?.slice(pages.output, pages.output + pages.step);

  return (
    <div className="min-vh-100">
      <TopBar title="SISPEK" subtitle="Records Raw Data">
        <TopFilter hook={topFilter} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title">
            <h3>Raw Data</h3>
          </div>
          <div className="card-body">
            <div className="d-flex mb-3">
              <select
                value={filter.mil}
                onChange={({ currentTarget }) => setFilter({ ...filter, mil: currentTarget.value, cems: "" })}
                className="me-2 w-auto form-select rounded-pill"
              >
                {mills?.map((mil) => (
                  <option key={mil.uuid} value={mil.uuid}>
                    {mil.name}
                  </option>
                ))}
                {!user?.mil && <option value="">All Mills</option>}
              </select>
              <select
                className="me-2 w-auto form-select rounded-pill"
                onChange={({ currentTarget }) => handleChangeArea(currentTarget.value)}
                value={filter.area}
              >
                {Object.values(Area)
                  ?.filter((item) => (user?.area.toLowerCase() === "all" ? item : item === user?.area))
                  .map((area) => (
                    <option key={area} value={area}>
                      {area}
                    </option>
                  ))}
              </select>

              <select
                value={filter.cems}
                onChange={({ currentTarget }) => setFilter({ cems: currentTarget.value })}
                className="me-2 w-auto form-select rounded-pill"
              >
                {user?.cems.length === 0 && <option value="">Select CEMS</option>}
                {user?.cems?.length !== 0
                  ? user?.cems?.map((cem) => (
                      <option key={cem.uuid} value={cem.uuid}>
                        {cem.name}
                      </option>
                    ))
                  : cems
                      ?.filter(({ mil }) => mil.uuid == filter.mil || !filter.mil)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((cem) => (
                        <option key={cem.uuid} value={cem.uuid}>
                          {cem.name}
                        </option>
                      ))}
              </select>

              <Button
                className="ms-auto"
                icon={BsFileEarmarkArrowDown}
                onClick={() => {
                  if (tableData?.length) rawToExcel(columns, tableData, undefined, filter.area);
                }}
                variant="primary"
              >
                Export
              </Button>
            </div>
            <div className="overflow-auto">
              <table className="table table-striped table-data table-center">
                <thead>
                  <tr>
                    <th>CEMS</th>
                    <th>Date</th>
                    <th>Time</th>
                    {columns?.map((col) => (
                      <th key={col.name}>
                        {col.name} measured <br /> {col.uom}
                      </th>
                    ))}
                    {columns
                      ?.filter(({ name }) => checkCorrected(name, filter.area))
                      .map((col) => (
                        <th key={col.name}>
                          {col.name} corrected <br /> {col.uom}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <RenderIf isTrue={isLoading}>
                    <tr className="text-center">
                      <td className="text-center py-4" colSpan={2 * columns.length + 3}>
                        <Loading />
                      </td>
                    </tr>
                  </RenderIf>
                  <RenderIf isTrue={!isLoading}>
                    {showTable?.length ? (
                      showTable?.map((row, idx) => (
                        <tr key={`row-${idx}`}>
                          <td className="text-nowrap">{row.cems}</td>
                          <td className="text-nowrap">{moment(row.date).format("YYYY-MM-DD")}</td>
                          <td>{moment(row.date).format("HH:mm")}</td>
                          {row.values.map((v, idx) => (
                            <td
                              key={`params-${idx}`}
                              className={
                                row.params?.[idx] && (Number(v[0]) > Number(row.params?.[idx]) || Number(v[0]) < 0)
                                  ? "bg-error text-white"
                                  : ""
                              }
                            >
                              {v[0] && Number(v[0])?.toFixed(2)}
                            </td>
                          ))}
                          {row.values
                            .map((v, idx) => (
                              <td
                                key={`params-${idx}`}
                                className={
                                  row.params?.[idx] && (Number(v[1]) > Number(row.params?.[idx]) || Number(v[1]) < 0)
                                    ? "bg-error text-white"
                                    : ""
                                }
                              >
                                {v[1] && Number(v[1])?.toFixed(2)}
                              </td>
                            ))
                            .filter((_, idx) => {
                              return checkCorrected(columns[idx].name, filter.area);
                            })}
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td className="text-center py-4" colSpan={2 * columns.length + 3}>
                          No data.
                        </td>
                      </tr>
                    )}
                  </RenderIf>
                </tbody>
              </table>
            </div>
            <div className="my-2 d-flex justify-content-end">
              <Pagination hook={pages} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RecordRawPage;
