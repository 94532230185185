import useSWR from "swr"
import useAuth from "./useAuth"

const useProfile = () => {
  const { isLogin } = useAuth()
  const { data: userData } = useSWR("/me", () => isLogin())

  const me = userData ? userData : undefined


  return me
}

export default useProfile