import { ApiResponse } from "../types/api-response"
import { User } from "../types/user"
import useAuth from "./useAuth"
import useSWR from "swr"

const useUsers = () => {
  const url = "/user"
  const { fetcher } = useAuth()
  const { data, error, mutate } = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<User[]>>(url)
      .then(({ data }) => {
        return data.data
      }).catch(err => console.error('co', err))
  )

  return { data, error, mutate, isLoading: !error && !data, }
}

export default useUsers
