import { ApiResponse } from "../types/api-response";
import { TopFilterOutput } from "./useTopFilter";
import useAuth from "./useAuth";
import useSWR from "swr";
import { Area, AreaType } from "../enum/area.enum";

export type PaginationQuery = {
  page?: number;
  limit?: number;
};

export interface AlertResponse {
  uuid: string;
  date: string;
  time: string;
  activity: string;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  mil: Mil;
  cem?: Cem;
}

interface ResponseInterface {
  meta: {
    page: number;
    last_page: number;
    length: number;
    total_data: number;
  };
  data: AlertResponse[];
}

interface Mil {
  uuid: string;
  name: string;
  coordinate: Coordinate;
  created_at: string;
  updated_at: string;
  deleted_at: any;
}

interface Coordinate {
  lat: string;
  long: string;
}

interface Cem {
  uuid: string;
  name: string;
  code: string;
  coordinate: Coordinate2;
  area: string;
  regulation_references: string[];
  created_at: string;
  updated_at: string;
  deleted_at: any;
}

interface Coordinate2 {
  lat: string;
  long: string;
}

type QueryType = TopFilterOutput & AreaType & PaginationQuery;

const useAlerts = (query: QueryType, mils?: string, cem?: string) => {
  const { _type, time, area, page, limit, ...q } = query;
  const { fetcher } = useAuth();
  const url = `/alerts/${_type}?area=${area}&page=${page}&limit=${limit}`;
  let queryString = {};
  if (mils) {
    queryString = { ...q, mils };
  } else if (!mils && cem) {
    queryString = { ...q, cem };
  } else {
    queryString = q;
  }
  const { data, mutate, error } = useSWR([url, JSON.stringify(queryString)], (url, q) =>
    fetcher()
      .get<ApiResponse<ResponseInterface>>(url, { params: JSON.parse(q) })
      .then(({ data }) => data.data)
  );

  return { data, mutate, error, isLoading: !error && !data };
};

export default useAlerts;
