import jsPDF from "jspdf";
import html2canvas from "html2canvas"

interface PrintToPdfInterface {
  idElement: string;
  nameFile: string;
}

const html2Helper = {
  printToPDF(props: PrintToPdfInterface): void{
    const {idElement, nameFile} = props
    const input: any = document.getElementById(idElement);
    html2canvas(input, {
        allowTaint: false,
      })
      .then((canvas) => {
        let pdf = null;
        const imgData = canvas.toDataURL("image/png");
        if (canvas.width > canvas.height) {
          pdf = new jsPDF("l", "mm", [canvas.width, canvas.height]);
        } else {
          pdf = new jsPDF("p", "mm", [canvas.height, canvas.width]);
        }

        pdf.addImage(imgData, "png", 0, 0, canvas.width, canvas.height);
        // pdf.output("dataurlnewwindow");
        pdf.save(nameFile);
      });
  }
}

export default html2Helper