import { ApiResponse } from "../types/api-response";
import { TopFilterOutput } from "./useTopFilter";
import useAuth from "./useAuth";
import useSWR from "swr";

export interface NotificationsResponse {
  uuid: string;
  type: "Alert" | "Announcement";
  message: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  mil: Mil;
  cem: Cem;
}

interface Mil {
  uuid: string;
  name: string;
}

interface Cem {
  uuid: string;
  name: string;
}

type PaginationQuery = {
  page?: number;
  limit?: number;
};

interface ResponseInterface {
  meta: {
    page: number;
    last_page: number;
    length: number;
    total_data: number;
  };
  data: NotificationsResponse[];
}

const useNotificationBanners = (filter: PaginationQuery) => {
  const { fetcher } = useAuth();
  const url = "/notifications/banner";
  const output = useSWR([url, JSON.stringify(filter)], (url, data) =>
    fetcher()
      .get<ApiResponse<ResponseInterface>>(url, {
        params: JSON.parse(data),
      })
      .then(({ data }) => data.data)
  );
  return output;
};

export default useNotificationBanners;
