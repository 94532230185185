import { FunctionComponent, h } from "preact"

type FilterLabelProps = h.JSX.HTMLAttributes<HTMLDivElement>

const FilterLabel: FunctionComponent<FilterLabelProps> = ({
  className,
  children,
}) => {
  return <div className={"filter-button active " + className}>{children}</div>
}

export default FilterLabel
