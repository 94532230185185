import "./style.scss"

import { FunctionComponent, h } from "preact"

type FilterButtonProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  active?: boolean
}

const FilterButton: FunctionComponent<FilterButtonProps> = ({
  active,
  children,
  ...props
}) => {
  return (
    <div className={`filter-button ${active ? "active" : ""}`} {...props}>
      {children}
    </div>
  )
}

export default FilterButton
