/** type-filter */
export enum SensorTypeFilter {
  hour = "hour",
  day = "day",
  raw = "raw",
}

/** type-data query */
export enum SensorTypeQuery {
  overview = "overview",
  raw = "raw",
  percentage = "percentage",
  emission = "emission",
  logs = "logs",
}
