import { Fragment, FunctionComponent, h } from "preact";

type RenderIfProps = {
  isTrue: boolean
} & Record<string, any>

const RenderIf: FunctionComponent<RenderIfProps> = ({ isTrue, children }) => {
  return <Fragment>{isTrue ? children : null}</Fragment>;
};

export default RenderIf;
