import { BiGlobe, BiListUl, BiServer } from "react-icons/bi"
import { Role, RoleListWithoutMemberAndOperator } from "../../enum/roles.enum"

type MenuITem = {
  label: string
  link?: string,
  icon?: any,
  sub: {
    label: string
    link: string,
    icon?: any
  }[],
  permissions?: any[]
}[]

const menu: MenuITem = [
  {
    label: "Dashboard",
    icon: '/assets/icons/svg/dashboard.svg',
    sub: [
      { label: "Overview", link: "/dashboard/overview", icon: '/assets/icons/svg/list.svg' },
      { label: "Map", link: "/dashboard/map", icon: '/assets/icons/svg/map-point.svg' },
    ],
  },
  {
    label: "RECORDS",
    icon: '/assets/icons/svg/copy.svg',
    sub: [
      { label: "Raw Data", link: "/record/raw", icon: '/assets/icons/svg/file.svg' },
      { label: "Emission Data", link: "/record/emission", icon: '/assets/icons/svg/file.svg' },
      { label: "Percentage Data", link: "/record/percentage", icon: '/assets/icons/svg/file.svg' },
      { label: "Alert Log", link: "/record/alert-log", icon: '/assets/icons/svg/file.svg' },
    ],
  },
  {
    label: "Manual Report",
    link: "/manual-report",
    icon: '/assets/icons/svg/task.svg',
    sub: [],
  },
  {
    label: "Analytics",
    link: "/analytics",
    icon: '/assets/icons/svg/analytics.svg',
    sub: [],
  },
  {
    label: "NCR",
    icon: '/assets/icons/svg/atom.svg',
    sub: [
      { label: "NCR List", link: "/ncr/list" },
      { label: "KLHK", link: "/ncr/klhk" },
    ],
  },
  {
    label: "Technical Data",
    icon: '/assets/icons/svg/technical.svg',
    link: "/technical-data",
    sub: [],
  },
  {
    label: "Admin",
    sub: [{ label: "User", link: "/admin/user" }],
    icon: '/assets/icons/svg/user-setting.svg',
    permissions: RoleListWithoutMemberAndOperator
  },
  {
    label: "Help",
    sub: [],
    link: "/help",
    icon: '/assets/icons/svg/help.svg'
  },
]

export default menu
