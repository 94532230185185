import { ApiResponse } from "../types/api-response"
import { SensorTypeQuery } from "../enum/sensor.enum"
import { TopFilterOutput } from "./useTopFilter"
import useAuth from "./useAuth"
import useSWR from "swr"
import { Area, AreaType } from "../enum/area.enum"

export type OverviewDataResponse = {
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  mils: {
    uuid: string;
    name: string;
    chimney_code: string;
    coordinate: {
      lat: string;
      long: string;
    };
    created_at: string;
    updated_at: string;
    deleted_at: string;
    cems: {
      uuid: string;
      name: string;
      coordinate: {
        lat: string;
        long: string;
      };
      created_at: string;
      updated_at: string;
      deleted_at: string;
      parameters: {
        uuid: string;
        name: string;
        uom: string;
        upper_threshold: number;
        lower_threshold: number;
        created_at: string;
        updated_at: string;
        deleted_at: string;
        payloads: {
          uuid: string;
          cems: string;
          name: string;
          value: number;
          value_condition_override: number;
          measured_at: string;
          created_at: string;
          updated_at: string;
          deleted_at: string;
        };
        last: number;
        avg: number;
        max: number;
        min: number;
        data_valid: {
          amount: number;
          percentage: number;
        };
        data_invalid: number;
        data_comply: {
          amount: number;
          percentage: number;
        };
        data_incomply: number;
        ncr: number;
      }[];
    }[];
  }[];
}[]



const useOverview = (filter: TopFilterOutput & AreaType) => {
  const { fetcher } = useAuth()

  const { _type, ...ops } = filter

  const url = `/sensor/${  _type}`
  const body = JSON.stringify({
    mils: "all",
    type: SensorTypeQuery.overview,
    ...ops,
  })

  const output = useSWR([url, body], (url, body) =>
    fetcher()
      .get<ApiResponse<OverviewDataResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data
      })
  )

  return {
    ...output,
    isLoading: !output.error && !output.data,
  }
}

export default useOverview
