import { FunctionalComponent, h } from "preact"
import { Route, Router } from "preact-router"

import { ConfirmProvider } from "../context/useConfirm"
import Dashboard from "./dashboard"
import LoginPage from "../routes/login"
import { useEffect } from "preact/hooks"

const App: FunctionalComponent = () => {
  useEffect(() => {
    document.title = "APP - SISPEK"
  }, [])
  return (
    <div id="preact_root">
      <ConfirmProvider>
        <Router>
          <Route path="/" component={LoginPage} />
          <Dashboard default />
        </Router>
      </ConfirmProvider>
    </div>
  )
}

export default App
