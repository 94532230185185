import { FunctionComponent, h, Fragment } from "preact";
import { Role, RoleList } from "../../../enum/roles.enum";
import { useState, useEffect } from "preact/hooks";

import { AxiosApiError } from "../../../types/api-response";
import { Button } from "../../../parts/buttons";
import { User } from "../../../types/user";
import { mutate } from "swr";
import useAllCompany, { AllCompanyResponse } from "../../../hooks/useAllCompany";
import useAuth from "../../../hooks/useAuth";
import useObjectState from "../../../hooks/useObjectState";
import { Area } from "../../../enum/area.enum";
import RenderIf from "../../../components/RenderIf";
import { MultiSelect } from "react-multi-select-component";
import { MultiSelectItem } from "./new-user";

type NewUserProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show: boolean;
  userData: User;
  onHide: () => void;
};

const EditUser: FunctionComponent<NewUserProps> = ({ userData, show, onHide }) => {
  const { data: companies } = useAllCompany();
  const [cems, setCems] = useState<any>([]);
  const defaultState = {
    name: userData.name,
    email: userData.email,
    role: userData.role,
    company: { uuid: userData.company?.uuid },
    mil: { uuid: userData.mil?.uuid },
    notification: userData.notification,
    notification_interval: 6,
    area: userData.area,
    cems: [],
  };

  const cemsDefault: any = userData.cems?.map((cem) => ({
    value: cem.uuid,
    label: cem.name,
  }));
  const [cemsSelected, setCemsSelected] = useState<MultiSelectItem[]>(cemsDefault);
  const [user, setUser] = useObjectState(defaultState);
  const { fetcher } = useAuth();

  const generateCemsOption = (companies?: AllCompanyResponse[]) => {
    const mil = companies
      ?.find(({ uuid }) => uuid == user.company.uuid)
      ?.mils?.filter((mil) => mil.uuid === user.mil.uuid);

    return (
      mil &&
      mil[0]?.cems
        .filter((cem) => cem.area === user.area)
        .map(({ uuid, name }) => (
          <option key={uuid} value={uuid}>
            {name}
          </option>
        ))
    );
  };

  const getAllCems = (role: string): any[] => {
    if (role === Role.Operator || role === Role.Member) {
      let tempCems: any = null;

      if (user.mil.uuid) {
        const mil = companies
          ?.find(({ uuid }) => uuid == user.company.uuid)
          ?.mils?.filter((mil) => mil.uuid === user.mil.uuid);

        if (mil) {
          tempCems = user.area === "All" ? mil[0]?.cems : mil[0]?.cems.filter((cem) => cem.area === user.area);
        }
      } else {
        tempCems = [];
      }

      return tempCems;
    }

    return [];
  };

  const getOneCem = (companies?: AllCompanyResponse[], uuid?: string): any => {
    const mil = companies
      ?.find(({ uuid }) => uuid == user.company.uuid)
      ?.mils?.filter((mil) => mil.uuid === user.mil.uuid);

    return mil && mil[0]?.cems.filter((cem) => cem.area === user.area && cem.uuid === uuid);
  };

  const getCems = (companies?: AllCompanyResponse[], cems?: MultiSelectItem[]) => {
    const tempCems: any[] = [];
    cems?.forEach((item: MultiSelectItem) => {
      companies
        ?.find(({ uuid }) => uuid == user.company.uuid)
        ?.mils?.forEach((mil) => {
          mil.cems.forEach((cem) => {
            if (cem.uuid === item?.value) {
              const newCem: any = {
                ...cem,
                parameters: cem.parameters,
              };

              delete newCem.parameters;

              tempCems.push(newCem);
            }
          });
        });
    });

    return tempCems;
  };
 
  useEffect(() => {
    setUser(defaultState);
    setCemsSelected(cemsDefault);

  }, [userData]);

  const handleSubmit = () => {
    const formData: any = {
      name: user.name,
      email: user.email,
      role: user.role,
      company: user.company,
      mil: user.mil,
      notification: user.notification,
      notification_interval: user.notification_interval,
      area: user.area,
      cems: [],
    };

    if (user?.mil?.uuid == "") {
      formData.mil = null;
    }

    if (formData.role == Role.Admin && formData.company.uuid == "") {
      formData.cems = null;
    } else {
      formData.cems = getCems(companies, cemsSelected);
    }

    fetcher()
      .patch("/user/" + userData.uuid, formData)
      .then(() => {
        if (onHide) onHide();
        setUser(defaultState);
        setCemsSelected(cemsDefault);
        mutate("/user");
      })
      .catch((e: AxiosApiError) => {
        const msg = e.response?.data?.data?.message || e.response?.data?.message || "Server Error";
        if (alert) {
          if (Array.isArray(msg)) alert(msg.map((t) => "- " + t).join("\n"));
          else alert(msg);
        }
      });
  };
  const handleCancel = () => {
    
    // console.log(cemsSelected)
    if (onHide) onHide();
    setUser(defaultState);
    setCemsSelected(cemsDefault);
  };
  
  // useEffect(()=>{
  //   setCemsSelected(cemsDefault);
  // },[cemsSelected])

  useEffect(() => {
    setCems(() => []);
    if (
      user.company.uuid != defaultState.company.uuid ||
      user.mil.uuid != defaultState.mil.uuid ||
      user.area != defaultState.area ||
      user.role != defaultState.role
    ) {
      setCemsSelected([]);
    }

    const mil = companies
      ?.find(({ uuid }) => uuid == user.company.uuid)
      ?.mils?.filter((mil) => (user.mil.uuid ? mil.uuid === user.mil.uuid : !!mil.uuid));

    let cems: any = [];
    mil?.forEach((mil) => {
      const newCems = mil?.cems
        .filter((cem) => (user.area == "All" ? !!cem.uuid : cem.area === user.area))
        .map(({ uuid, name }) => ({ uuid, name }));

      cems = [...cems, ...newCems];
    });

    const newCems = cems.map((item: any) => ({
      value: item.uuid,
      label: item.name,
    }));

    setCems(() => newCems);
  }, [user.company.uuid, user.mil.uuid, user.area, user.role]);
  return (
    <div
      className="modal-backdrop"
      style={{
        display: show ? "inherit" : "none",
        backgroundColor: "#00000099",
        overflowY: "auto",
      }}
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content card">
          <div className="card-body d-flex justify-content-between">
            <h5 class="card-title">Edit User</h5>
            <div>
              <Button variant="primary" onClick={handleSubmit}>
                Save
              </Button>
              <Button variant="tertiary" className="ms-2" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
          <div className="card-body px-4">
            <div class="form-group pb-2">
              <label>Name</label>
              <input
                value={user.name}
                required
                type="text"
                class="form-control"
                onChange={({ currentTarget }) => {
                  setUser({ name: currentTarget.value });
                }}
              />
            </div>
            <div class="form-group pb-2 ">
              <label>Email</label>
              <input
                value={user.email}
                required
                type="email"
                class="form-control"
                onChange={({ currentTarget }) => {
                  setUser({ email: currentTarget.value });
                }}
              />
            </div>
            <div class="form-group pb-2">
              <label>Role</label>
              <select
                name=""
                value={user.role}
                className="rounded-pill form-control"
                onChange={({ currentTarget }) => {
                  setUser({ role: currentTarget.value as Role });
                }}
                id=""
              >
                {RoleList?.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
            <div class="form-group pb-2">
              <label>Area</label>
              <select
                name=""
                value={user.area}
                className="rounded-pill form-control"
                onChange={({ currentTarget: e }) => setUser({ area: e.value })}
                id=""
              >
                <option value="All">All Area</option>
                {Object.values(Area).map((area) => (
                  <option key={area} value={area}>
                    {area}
                  </option>
                ))}
              </select>
            </div>
            <div class="form-group pb-2">
              <label>Affiliate</label>
              <select
                name=""
                className="rounded-pill form-control"
                value={user.company.uuid || ""}
                id=""
                onChange={({ currentTarget }) => {
                  setUser({ company: { uuid: currentTarget.value }, mil: { uuid: "" } });
                }}
              >
                {(userData?.role == Role.Admin || userData.role == Role.Member) && <option value="">HQ</option>}
                {companies?.map(({ uuid, name }) => (
                  <option key={uuid} value={uuid}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            {user?.company?.uuid && (
              <div class="form-group pb-2">
                <label>Mills</label>
                <select
                  name=""
                  className="rounded-pill form-control"
                  value={user.mil.uuid}
                  id=""
                  onChange={({ currentTarget }) => {
                    setUser({ mil: { uuid: currentTarget.value } });
                  }}
                >
                  <RenderIf isTrue={user.role !== Role.Member && user.role !== Role.Operator}>
                    <option value="">All Mills</option>
                  </RenderIf>
                  {companies
                    ?.find(({ uuid }) => uuid == user.company.uuid)
                    ?.mils?.map(({ uuid, name }) => (
                      <option key={uuid} value={uuid}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {/* <RenderIf isTrue={!!user.company.uuid && user.area !== "All"}>
              <div class="form-group pb-2">
                <label>CEMS</label>
                <select
                  name=""
                  className="rounded-pill form-control"
                  value={user.cems}
                  id=""
                  onChange={({ currentTarget }) => {
                    setUser({ cems: currentTarget.value });
                  }}
                >
                  <Fragment>
                    <option value="All">All CEMS</option>
                    {generateCemsOption(companies)}
                  </Fragment>
                </select>
              </div>
            </RenderIf> */}

            <RenderIf isTrue={!!user.company.uuid}>
              <div class="form-group pb-2">
                <label>CEMS</label>
                <div
                  className="w-100"
                  style={{
                    maxWidth: 400,
                  }}
                >
                  <MultiSelect options={cems} value={cemsSelected} onChange={setCemsSelected} labelledBy="Select" />
                </div>
              </div>
            </RenderIf>
            <div class="form-group pb-2">
              <label>Notification</label>
              <select
                name=""
                className="rounded-pill form-control"
                value={user.notification ? "on" : "off"}
                id=""
                onChange={({ currentTarget }) => {
                  setUser({ notification: currentTarget.value == "on" });
                }}
              >
                <option value="on">On</option>
                <option value="off">Off</option>
              </select>
            </div>
            <div class="form-group pb-2 ">
              <label>Email Notification Interval</label>
              <input value={user.notification_interval + " Hours"} required type="text" disabled class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
