import {} from "react-icons/bi";

import { Fragment, FunctionComponent, h } from "preact";

import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { Button } from "../../parts/buttons";
import Container from "../../parts/container";
import Pagination from "../../components/pagination";
import { SensorTypeQuery } from "../../enum/sensor.enum";
import TopBar from "../../components/topbar";
import TopFilter from "../../components/TopFilter";
import distinctSensor from "../../utils/distinctSensor";
import { emissionToExcel } from "./toexcel";
import moment from "moment";
import style from "./style.scss";
import useCems from "../../hooks/useCems";
import { useEffect } from "preact/hooks";
import useMills from "../../hooks/useMills";
import useObjectState from "../../hooks/useObjectState";
import usePagination from "../../hooks/usePagination";
import useRaw from "../../hooks/useRaw";
import useTopFilter from "../../hooks/useTopFilter";
import { Area } from "../../enum/area.enum";
import useArea from "../../hooks/useArea";
import RenderIf from "../../components/RenderIf";
import Loading from "../../components/loading";
import { User } from "../../types/user";
import useAuth from "../../hooks/useAuth";

type RecordEmissionPageProps = h.JSX.HTMLAttributes<HTMLDivElement>;

export const generateOptionArea = (user: User) => (
  <Fragment>
    <option value="">Select Area</option>
    {Object.values(Area)
      ?.filter((item) => (user?.area?.toLowerCase() === "all" ? item : item === user?.area))
      .map((area) => (
        <option key={area} value={area}>
          {area}
        </option>
      ))}
    <option />
  </Fragment>
);

const RecordEmissionPage: FunctionComponent<RecordEmissionPageProps> = () => {
  const topFilter = useTopFilter();
  // const  isdaily = false
  if (topFilter.period.active == "DAILY") {
    const isdaily = true;
  } else {
    const isdaily = false;
  }
  const { user } = useAuth();

  const [currentArea, storeArea, removeArea] = useArea(Area.PowerPlant);
  // console.log(isdaily)
  const [filter, setFilter] = useObjectState({
    mil: "",
    cems: "",
    area: user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant,
  });
  const { data: mills } = useMills();
  const { data: cems } = useCems("", filter.area);
  const { data, isLoading } = useRaw({
    mils: filter.mil,
    filter: topFilter.output,
    type: SensorTypeQuery.emission,
    area: filter.area,
  });

  const columns = data ? distinctSensor(data, filter.area, filter.cems) : [];
  // const timer = setTimeout(() => console.log('Initial timeout!'), 1000);
  // if (timer){
  //   alert("Error : Too Much Data Load")
  //   clearTimeout(timer);
  // }
  const tableData = data
    ?.map((comp) =>
      comp.mils
        .filter(({ uuid }) => !filter.mil || uuid == filter.mil)
        .map(({ cems }) =>
          cems
            .filter(({ uuid }) => !filter.cems || uuid == filter.cems)
            .map((cem) =>
              cem.payloads?.map((payload) => {
                const values = columns.map((col) => payload.payloads.find(({ name }) => name == col.name)?.avg_valid);
                const loads = columns.map((col) => payload.payloads.find(({ name }) => name == col.name)?.avg_load);
                return {
                  cemsId: cem.uuid,
                  cems: cem.name,
                  date: payload.measured_at,
                  values,
                  loads,
                };
              })
            )
            .reduce((a, b) => (b ? a?.concat(b) : a), [])
        )
        .reduce((a, b) => (b ? a?.concat(b) : a), [])
    )
    .reduce((a, b) => (b ? a?.concat(b) : a), [])
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const pages = usePagination({ count: tableData?.length || 0, step: 18 });
  const showTable = tableData
    ?.filter(({ cemsId }) => cemsId == filter.cems)
    ?.slice(pages.output, pages.output + pages.step);
  // .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const handleChangeArea = (value: any) => {
    storeArea(value, () =>
      setFilter({
        ...filter,
        area: value,
      })
    );
  };

  useEffect(() => {
    if (currentArea) {
      setFilter({
        ...filter,
        area: currentArea,
      });
    }
  }, [currentArea]);

  useEffect(() => {
    if (user?.area) {
      storeArea(user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant);
      setFilter({
        mil: user?.mil?.uuid ? user?.mil?.uuid : user?.mil,
        cems: user?.cems.length !== 0 ? user?.cems[0].uuid : "",
      });
    }
  }, [user?.area]);

  useEffect(() => {
    if (!user?.mil && mills?.length !== 0) {
      setFilter({
        mil: mills && mills[0].uuid,
      });
    }
  }, [mills, user?.mil]);

  return (
    <div className="min-vh-100">
      <TopBar title="SISPEK" subtitle="Records Emission Data">
        <TopFilter hook={topFilter} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title">
            <h3>Emission Data</h3>
          </div>
          <div className="card-body">
            <div className="d-flex mb-3">
              <select
                value={filter.mil}
                onChange={({ currentTarget }) => setFilter({ ...filter, mil: currentTarget.value, cems: "" })}
                className="me-2 w-auto form-select rounded-pill"
              >
                {mills?.map((mil) => (
                  <option key={mil.uuid} value={mil.uuid}>
                    {mil.name}
                  </option>
                ))}
                {!user?.mil && <option value="">All Mills</option>}
              </select>
              <select
                className="me-2 w-auto form-select rounded-pill"
                onChange={({ currentTarget }) => handleChangeArea(currentTarget.value)}
                value={filter.area}
              >
                {Object.values(Area)
                  ?.filter((item) => (user?.area?.toLowerCase() === "all" ? item : item === user?.area))
                  .map((area) => (
                    <option key={area} value={area}>
                      {area}
                    </option>
                  ))}
              </select>

              <select
                value={filter.cems}
                onChange={({ currentTarget }) => setFilter({ cems: currentTarget.value })}
                className="me-2 w-auto form-select rounded-pill"
              >
                {user?.cems.length === 0 && <option value="">Select CEMS</option>}
                {user?.cems?.length !== 0
                  ? user?.cems?.map((cem) => (
                      <option key={cem.uuid} value={cem.uuid}>
                        {cem.name}
                      </option>
                    ))
                  : cems
                      ?.filter(({ mil }) => mil.uuid == filter.mil || !filter.mil)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((cem) => (
                        <option key={cem.uuid} value={cem.uuid}>
                          {cem.name}
                        </option>
                      ))}
              </select>

              <Button
                className="ms-auto"
                icon={BsFileEarmarkArrowDown}
                variant="primary"
                onClick={() => {
                  if (tableData) emissionToExcel(topFilter.period.active, columns, tableData, "Emission");
                }}
              >
                Export
              </Button>
            </div>
            <div className="overflow-auto">
              <table className="table table-striped table-data table-center">
                <thead>
                  <tr>
                    <th>CEMS</th>
                    <th>Date</th>
                    <th>Time</th>
                    {columns?.map((col) => (
                      <th key={col.name}>
                        {col.name} <br /> {col.uom}
                      </th>
                    ))}
                    {columns
                      ?.filter(({ has_load }) => has_load)
                      .filter(({ name }) => name != "Flow")
                      .map((col) => (
                        <th key={col.name}>
                          {col.name} load <br /> (kg/h)
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody className={style.emission__data}>
                  <RenderIf isTrue={isLoading}>
                    <tr className="text-center">
                      <td className="text-center py-4" colSpan={2 * columns.length + 3}>
                        <Loading />
                      </td>
                    </tr>
                  </RenderIf>
                  <RenderIf isTrue={!isLoading}>
                    {showTable?.length ? (
                      <Fragment>
                        {showTable?.map((row, idx) => (
                          <tr key={`row-${idx}`}>
                            <td>{row.cems}</td>
                            <td>{moment(row.date).format("YYYY-MM-DD")}</td>
                            <td>{moment(row.date).format("HH:mm")}</td>
                            {row.values.map((v, idx) => (
                              <td key={`params-${idx}`}>{v?.toFixed(2)}</td>
                            ))}
                            {row.loads
                              .filter((_, idx) => columns[idx].has_load && columns[idx].name != "Flow")
                              .map((v, idx) => (
                                <td key={`params-${idx}`}>{v?.toFixed(2)}</td>
                              ))}
                          </tr>
                        ))}
                        <tr className={style.avg}>
                          <td rowSpan={4} style={{ backgroundColor: "white" }} />
                          <td rowSpan={4}>Total Emission</td>
                        </tr>
                        <tr className={style.max}>
                          <td>MAX</td>
                          {columns.map((_, idx) => (
                            <td key={idx}>
                              {tableData
                                ?.map(({ values }) => values[idx])
                                // .reduce((a, b) => ((Number(a)) > (Number(b)) ? Number(a) : Number(b)))
                                .reduce((a, b) => ((Number(a) || 0) > (Number(b) || 0) ? Number(a) || 0 : Number(b)))
                                ?.toFixed(2)}
                            </td>
                          ))}
                          {columns
                            .filter(({ has_load }) => has_load)
                            .filter(({ name }) => name != "Flow")
                            .map(({ uuid }, idx) => (
                              <td key={idx}>
                                {tableData
                                  ?.map(({ loads }) => loads[columns.map(({ uuid }) => uuid).indexOf(uuid)])
                                  .reduce((a, b) =>
                                    // (Number(a)) > (Number(b) ) ? Number(a) : Number(b)
                                    (Number(a) || 0) > (Number(b) || 0) ? Number(a) || 0 : Number(b)
                                  )
                                  ?.toFixed(2)}
                              </td>
                            ))}
                        </tr>
                        <tr className={style.min}>
                          <td>MIN</td>
                          {columns.map((_, idx) => (
                            <td key={idx}>
                              {tableData
                                ?.map(({ values }) => values[idx])
                                .reduce((a, b) => (Number(a) >= Number(b) ? Number(b) : Number(a)))
                                ?.toFixed(2)}
                            </td>
                          ))}
                          {columns
                            .filter(({ has_load }) => has_load)
                            .filter(({ name }) => name != "Flow")
                            .map(({ uuid }, idx) => (
                              <td key={idx}>
                                {tableData
                                  ?.map(({ loads }) => loads[columns.map(({ uuid }) => uuid).indexOf(uuid)])
                                  .reduce((a, b) => (Number(a) >= Number(b) ? Number(b) : Number(a)))
                                  ?.toFixed(2)}
                              </td>
                            ))}
                        </tr>
                        <tr className={style.avg}>
                          <td>AVG</td>
                          {columns.map((_, idx) => (
                            <td key={idx}>
                              {(
                                (tableData
                                  ?.map(({ values }) => values[idx])
                                  .reduce((a, b) => (Number(a) || 0) + (Number(b) || 0)) || 0) /
                                (tableData?.map(({ values }) => values[idx]).filter((a) => !isNaN(Number(a))).length ||
                                  0)
                              ).toFixed(2)}
                            </td>
                          ))}
                          {columns
                            .filter(({ has_load }) => has_load)
                            .filter(({ name }) => name != "Flow")
                            .map(({ uuid }, idx) => (
                              <td key={idx}>
                                {(
                                  (tableData
                                    ?.map(({ loads }) => loads[columns.map(({ uuid }) => uuid).indexOf(uuid)])
                                    .reduce((a, b) => (Number(a) || 0) + (Number(b) || 0)) || 0) /
                                  (tableData
                                    ?.map(({ loads }) => loads[columns.map(({ uuid }) => uuid).indexOf(uuid)])
                                    .filter((a) => !isNaN(Number(a))).length || 0)
                                )?.toFixed(2)}
                              </td>
                            ))}
                        </tr>
                        <tr className={style.total}>
                          <td colSpan={3}>Total Load</td>
                          {columns.map((_, idx) => (
                            <td key={idx} />
                          ))}
                          {columns
                            .filter(({ has_load }) => has_load)
                            .filter(({ name }) => name != "Flow")
                            .map(({ uuid }, idx) => (
                              <td key={idx}>
                                {topFilter.period.active === "HOURLY"
                                  ? tableData
                                      ?.map(({ loads }) => loads[columns.map(({ uuid }) => uuid).indexOf(uuid)])
                                      .reduce((a, b) => (Number(a) || 0) + (Number(b) || 0))
                                      ?.toFixed(2)
                                  : (
                                      ((tableData
                                        ?.map(({ loads }) => loads[columns.map(({ uuid }) => uuid).indexOf(uuid)])
                                        .reduce((a, b) => (Number(a) || 0) + (Number(b) || 0)) || 0) /
                                        (tableData
                                          ?.map(({ loads }) => loads[columns.map(({ uuid }) => uuid).indexOf(uuid)])
                                          .filter((a) => !isNaN(Number(a))).length || 0)) *
                                      24 *
                                      (tableData
                                        ?.map(({ loads }) => loads[columns.map(({ uuid }) => uuid).indexOf(uuid)])
                                        .filter((a) => !isNaN(Number(a))).length || 0)
                                    )?.toFixed(2)}
                              </td>
                            ))}
                        </tr>
                      </Fragment>
                    ) : filter.cems ? (
                      <tr>
                        <td
                          colSpan={
                            columns.length +
                            columns.filter(({ has_load }) => has_load).filter(({ name }) => name != "Flow").length +
                            3
                          }
                        >
                          No data.
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            columns.length +
                            columns.filter(({ has_load }) => has_load).filter(({ name }) => name != "Flow").length +
                            3
                          }
                        >
                          Please select mills and cems.
                        </td>
                      </tr>
                    )}
                  </RenderIf>
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end my-2">
              <Pagination hook={pages} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RecordEmissionPage;
