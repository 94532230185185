import { Fragment, FunctionComponent, h } from "preact";
import useTopFilter, { useTopFilterReturn } from "../../hooks/useTopFilter";

import FilterButton from "../../parts/filter/button";
import FilterDate from "../../parts/filter/date";
import FilterGroup from "../../parts/filter/group";
import FilterLabel from "../../parts/filter/label";
import { useState } from "preact/hooks";

type TopFilterProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  hook?: useTopFilterReturn;
};

const TopFilter: FunctionComponent<TopFilterProps> = ({ hook }) => {
  const { options, setRange, period } = hook || useTopFilter();
  const [resetRange, setResetRange] = useState(false);

  const handleSetRange = (r: any) => {
    setRange(r);
    setResetRange(false);
  };

  return (
    <Fragment>
      <div className="me-2">
        <FilterGroup>
          <FilterLabel className="d-xxl-block d-none">select Period :</FilterLabel>
          <select
            className="filter-select border-0 bg-transparent px-3"
            style={{ outline: "none" }}
            value={period.active}
            onChange={({ currentTarget }) => {
              period.change(currentTarget.value);
              options.change(0);
              setResetRange(true);
            }}
          >
            {period.data.map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>
        </FilterGroup>
      </div>

      <div className="me-2">
        <FilterGroup>
          {options.data.map(({ label }, idx) => (
            <FilterButton
              key={idx}
              active={idx == options.active}
              onClick={() => {
                options.change(idx);
                setResetRange(true);
              }}
            >
              {label}
            </FilterButton>
          ))}
          <FilterDate updateRange={(r) => handleSetRange(r)} reset={resetRange} />
        </FilterGroup>
      </div>
    </Fragment>
  );
};

export default TopFilter;
