import { ApiResponse } from "../types/api-response";
import { SensorTypeQuery } from "../enum/sensor.enum";
import { TopFilterOutput } from "./useTopFilter";
import useAuth from "./useAuth";
import useSWR from "swr";
import { AreaType } from "../enum/area.enum";

export type RawDataResponse = {
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  mils: Mil[];
}[];

interface Mil {
  uuid: string;
  name: string;
  coordinate: Coordinate;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  cems: Cem[];
}

interface Coordinate {
  lat: string;
  long: string;
}

interface Cem {
  uuid: string;
  name: string;
  code: string;
  coordinate: Coordinate2;
  area: string;
  regulation_references: string[];
  created_at: string;
  updated_at: string;
  deleted_at: any;
  parameters: (Parameter | OverviewParameter)[];
  payloads: Payload[];
}

interface Coordinate2 {
  lat: string;
  long: string;
}

export interface Parameter {
  uuid: string;
  name: string;
  uom: string;
  type: string;
  upper_threshold: number;
  lower_threshold: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  has_load?: boolean;
}

interface Payload {
  measured_at: string;
  total_data: number;
  total_valid: number;
  total_comply: number;
  total_percentage_comply?: number;
  percentage_valid: number;
  payloads: (PayloadPercentage | PayloadEmission)[];
}

export interface PayloadPercentage {
  measured_at: string;
  name: string;
  value?: number;
  value_condition_override?: number;
  avg_raw: number;
  avg_valid?: number;
  avg_load?: number;
  avg_comply?: number;
  percentage_comply?: number;
  len_data: number;
  len_valid: number;
  len_comply: number;
}

export interface PayloadEmission {
  measured_at: string;
  name: string;
  avg_valid: number;
  avg_load?: number;
  value?: number;
}

export interface OverviewParameter {
  uuid: string;
  name: string;
  uom: string;
  type: string;
  upper_threshold: number;
  lower_threshold: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  last: string;
  avg: number;
  max: number;
  min: number;
  data_valid: DataValid;
  data_invalid: number;
  data_comply: DataComply;
  data_incomply: number;
  has_load?: boolean;
  ncr: any;
  payloads: ParameterPayload[];
}

interface DataValid {
  amount: number;
  percentage: number;
}

interface DataComply {
  amount: number;
  percentage: number;
}

interface ParameterPayload {
  uuid: string;
  cems: string;
  name: string;
  is_comply: boolean;
  value: number;
  value_measured: string;
  value_corrected: string;
  value_condition_override: string;
  valid_data: boolean;
  measured_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
}

type QueryType = { mils?: string; type?: SensorTypeQuery; filter: TopFilterOutput };

const useRaw = (query: QueryType & AreaType) => {
  const { fetcher } = useAuth();
  const { filter, mils, area, ...ops } = query;
  const { _type, ...q } = filter;

  const url = `/sensor/${_type}?area=${area}`;
  const body = JSON.stringify({
    mils: mils || "all",
    type: SensorTypeQuery.raw,
    ...ops,
    ...q,
  });

  const output = useSWR([url, body], (url, body) =>
    fetcher()
      .get<ApiResponse<RawDataResponse>>(url, { params: JSON.parse(body) })
      .then(({ data }) => {
        return data.data;
      })
  );

  return {
    ...output,
    isLoading: !output.error && !output.data,
  };
};

export default useRaw;
