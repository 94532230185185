import "react-datepicker/dist/react-datepicker.css";

import { FunctionComponent, h } from "preact";

import { BiTimeFive } from "react-icons/bi";
import DatePicker from "react-datepicker";
import { useState, useEffect } from "preact/hooks";

type DateRange = [Date | null, Date | null];
type FilterDateProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  updateRange?: (date: [number, number]) => void;
  reset?: boolean;
};

const FilterDate: FunctionComponent<FilterDateProps> = ({ updateRange, reset, ...props }) => {
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (reset) {
      setDateRange([null, null]);
    }
  }, [reset]);

  return (
    <div className="filter-date" {...props}>
      <BiTimeFive className="icon" />
      <DatePicker
        className="date"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          if (Array.isArray(update)) {
            setDateRange(update);
            if (update[0] && update[1] && updateRange) updateRange([update[0]?.getTime(), update[1]?.getTime()]);
          }
        }}
        isClearable={true}
        placeholderText="start - end"
      />
    </div>
  );
};

export default FilterDate;
