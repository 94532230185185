import { useEffect, useState } from "preact/hooks";

import { SensorTypeFilter } from "../enum/sensor.enum";
import { toIsoString } from "../utils/dateformat";

export type Period = "DAILY" | "HOURLY" | "RAW";
export type Range = [number, number];
export type Option = { label: string; range: Range };

export type TopFilterOutput =
  | {
      _type: "periods";
      time: SensorTypeFilter;
      start: string;
      end: string;
      area?: string;
    }
  | {
      _type: "hourly";
      time: SensorTypeFilter;
      value: number;
      area?: string;
    };
export type useTopFilterReturn = {
  period: {
    data: Period[];
    active: Period;
    change: (period: string) => void;
  };
  options: {
    data: Option[];
    active: number;
    change: (opt: number) => void;
  };
  setRange: (range: Range) => void;
  range: Range;
  output: TopFilterOutput;
};
const useTopFilter = (isRaw: boolean = false): useTopFilterReturn => {
  const HOUR = 60 * 60 * 1000;
  const DAY = 24 * HOUR;
  const defaultPeriod: Period = isRaw ? "RAW" : "HOURLY";

  const [range, setRange] = useState(() => {
    const now = new Date().getTime();
    const start = now - 6 * HOUR;
    return [start, now] as Range;
  });

  const [period, setPeriod] = useState<Period>(defaultPeriod);
  const [option, setOption] = useState(0);
  const [options, setOptions] = useState<Option[]>([]);
  const periodList: Period[] = isRaw ? ["RAW", "HOURLY", "DAILY"] : ["HOURLY", "DAILY"];

  const changePeriod = (period: string) => {
    const now = new Date().getTime();
    switch (period) {
      case "RAW":
        setOptions([
          { label: "6h", range: [now - 6 * HOUR, now] },
          { label: "12h", range: [now - 12 * HOUR, now] },
          { label: "24h", range: [now - 24 * HOUR, now] },
        ]);
        setPeriod("RAW");
        setRange([now - 6 * HOUR, now]);
        break;
      case "HOURLY":
        setOptions([
          { label: "6h", range: [now - 6 * HOUR, now] },
          { label: "12h", range: [now - 12 * HOUR, now] },
          { label: "24h", range: [now - 24 * HOUR, now] },
        ]);
        setPeriod("HOURLY");
        setRange([now - 6 * HOUR, now]);
        break;
      case "DAILY":
        setPeriod("DAILY");
        setOptions([
          { label: "7d", range: [now - 7 * DAY, now] },
          { label: "14d", range: [now - 14 * DAY, now] },
          { label: "30d", range: [now - 30 * DAY, now] },
        ]);
        setRange([now - 7 * DAY, now]);
        break;
      default:
        setPeriod(defaultPeriod);
        setOptions([]);
    }
  };

  const changeRange = (range: Range) => {
    setRange(range);
    setOption(-1);
  };

  const changeOption = (opt: number) => {
    setOption(opt);
    if (options[opt]) setRange(options[opt].range);
  };

  useEffect(() => {
    changePeriod(defaultPeriod);
    changeOption(0);
  }, []);

  const toTime = () => {
    switch (period) {
      case "RAW":
        return SensorTypeFilter.raw;
      case "HOURLY":
        return SensorTypeFilter.hour;
      case "DAILY":
        return SensorTypeFilter.day;
      default:
        return SensorTypeFilter.hour;
    }
  };

  const getOutput = (): TopFilterOutput => {
    if (period == "HOURLY" || period == "RAW") {
      if (option > -1)
        return {
          _type: "hourly",
          time: toTime(),
          value: [6, 12, 24][option],
        };
      return {
        _type: "periods",
        time: toTime(),
        start: toIsoString(new Date(range[0])).split("T")[0],
        end: toIsoString(new Date(range[1])).split("T")[0],
      };
    }

    return {
      _type: "periods",
      time: toTime(),
      start: toIsoString(new Date(range[0])).split("T")[0],
      end: toIsoString(new Date(range[1])).split("T")[0],
    };
  };

  return {
    period: {
      data: periodList,
      active: period,
      change: changePeriod,
    },
    options: {
      data: options,
      active: option,
      change: changeOption,
    },
    setRange: changeRange,
    range,
    output: getOutput(),
  };
};

export default useTopFilter;
