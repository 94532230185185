import { OverviewParameter, Parameter, PayloadPercentage } from "../../hooks/useRaw";

import { AlertResponse } from "../../hooks/useAlerts";
import { CORRECTED, NON_PP_CORRECTED } from "../../utils/distinctSensor";
import Excel from "exceljs";
import { colName } from "../../utils/excel";
import moment from "moment";
import { Area } from "../../enum/area.enum";

const DATE_FORMAT = "YYYY-MM-DD HH:mm";

export const emissionToExcel = (
  period: string,
  columns: (Parameter | OverviewParameter)[],
  data: {
    cemsId: string;
    cems: string;
    date: string;
    values: (number | undefined)[];
    loads: (number | undefined)[];
  }[],
  name: string = "Raw Data"
) => {
  const book = new Excel.Workbook();
  const cems = data.filter((v, idx, arr) => arr.map(({ cemsId }) => cemsId).indexOf(v.cemsId) == idx) || [];

  cems.forEach(({ cems: cemName, cemsId }) => {
    const seedName = cemName.replace(/[^a-zA-Z0-9]/g, " ");
    const seed = book.addWorksheet(seedName);
    const loadCols = columns
      ?.map((data, idx) => ({ ...data, idx }))
      ?.filter(({ has_load }) => has_load)
      ?.filter(({ name }) => name != "Flow");

    seed.getCell(1, 1).value = "CEMS ID";
    seed.getCell(1, 2).value = "CEMS NAME";
    seed.getCell(1, 3).value = "DATE";

    columns?.forEach((param, idx) => {
      seed.getCell(1, 4 + idx).value = `${param.name} (${param.uom})`;
    });
    loadCols?.forEach((param, idx) => {
      seed.getCell(1, 4 + idx + columns.length).value = `${param.name} load (kg/h)`;
    });

    const cems = data?.filter(({ cemsId: id }) => id == cemsId);
    cems?.forEach((row, idx) => {
      seed.getCell(2 + idx, 1).value = row.cemsId;
      seed.getCell(2 + idx, 2).value = row.cems;
      seed.getCell(2 + idx, 3).value = moment(row.date).format(DATE_FORMAT);

      row.values?.forEach((val, col) => {
        seed.getCell(2 + idx, 4 + col).value = isNaN(Number(val)) ? null : Number(val);
      });

      loadCols.map(({ idx: loadIdx }, col) => {
        seed.getCell(2 + idx, 4 + col + columns.length).value = isNaN(Number(row.loads[loadIdx]))
          ? null
          : Number(row.loads[loadIdx]);
      });
    });
    seed.getCell(cems.length + 2, 2).value = "Total Emission";
    seed.getCell(cems.length + 2, 3).value = "MAX";
    seed.getCell(cems.length + 3, 3).value = "MIN";
    seed.getCell(cems.length + 4, 3).value = "AVG";
    seed.getCell(cems.length + 5, 3).value = "TOTAL LOAD";

    columns.concat(loadCols)?.forEach((row, idx) => {
      // console.log(idx,"------",columns.length,"-----", row)
      const [rowStart, rowEnd, col] = [2, cems.length + 1, colName(idx + 3)];
      seed.getCell(cems.length + 2, 4 + idx).value = {
        formula: `MAX(${col + rowStart}:${col + rowEnd})`,
        date1904: false,
      };
      seed.getCell(cems.length + 3, 4 + idx).value = {
        formula: `MIN(${col + rowStart}:${col + rowEnd})`,
        date1904: false,
      };
      seed.getCell(cems.length + 4, 4 + idx).value = {
        formula: `AVERAGEIF(${col + rowStart}:${col + rowEnd}, "<>0")`,
        date1904: false,
      };

      if (row.has_load == true && idx >= columns.length) {
        if (period == "HOURLY") {
          seed.getCell(cems.length + 5, 4 + idx).value = {
            formula: `SUMIF(${col + rowStart}:${col + rowEnd}, "<>0")`,
            // rata-rata x 24 x sum
            date1904: false,
          };
        } else {
          seed.getCell(cems.length + 5, 4 + idx).value = {
            formula: `AVERAGE(${col + rowStart}:${col + rowEnd})*24*COUNT(${col + rowStart}:${col + rowEnd})`,
            // rata-rata x 24 x sum
            date1904: false,
          };
        }
      }
    });
  });

  Download(book, name);
};

export const rawToExcel = (
  columns: (Parameter | OverviewParameter)[],
  data: {
    cemsId: string;
    cems: string;
    date: string;
    values: ([number, number] | undefined)[];
  }[],
  name: string = "Raw Data",
  area: Area
) => {
  const book = new Excel.Workbook();
  const seed = book.addWorksheet("Raw Data");

  seed.getCell(1, 1).value = "CEMS ID";
  seed.getCell(1, 2).value = "CEMS NAME";
  seed.getCell(1, 3).value = "DATE";

  columns?.forEach((param, idx) => {
    seed.getCell(1, 4 + idx).value = `${param.name} measured (${param.uom})`;
  });
  columns
    ?.filter((v) => (area === Area.PowerPlant ? CORRECTED.includes(v.name) : NON_PP_CORRECTED.includes(v.name)))
    .forEach((param, idx) => {
      seed.getCell(1, 4 + idx + columns.length).value = `${param.name} corrected (${param.uom})`;
    });

  data?.forEach((row, idx) => {
    seed.getCell(2 + idx, 1).value = row.cemsId;
    seed.getCell(2 + idx, 2).value = row.cems;
    seed.getCell(2 + idx, 3).value = moment(row.date).format(DATE_FORMAT);

    row.values?.forEach((val, col) => {
      seed.getCell(2 + idx, 4 + col).value = val?.[0] ? Number(val?.[0])?.toFixed(2) : null;
    });
    row.values
      ?.filter((_, idx) =>
        area === Area.PowerPlant ? CORRECTED.includes(columns[idx].name) : NON_PP_CORRECTED.includes(columns[idx].name)
      )
      .forEach((val, col) => {
        seed.getCell(2 + idx, 4 + col + row.values?.length).value = val?.[1] ? Number(val?.[1])?.toFixed(2) : null;
      });
  });
  Download(book, name);
};

export const percentageToExcel = (
  columns: (Parameter | OverviewParameter)[],
  data: {
    cemsId: string;
    cems: string;
    date: string;
    payload: {
      percentage_valid?: number;
      total_comply?: number;
      total_percentage_comply?: number;
    };
    values: PayloadPercentage[];
    // loads: (number | undefined)[]
  }[],
  name: string = "Percentage Data"
) => {
  const book = new Excel.Workbook();
  const seed = book.addWorksheet("Percentage Data");

  const loadCols = columns?.map((data, idx) => ({ ...data, idx }));

  seed.getCell(2, 1).value = "CEMS ID";
  seed.getCell(2, 2).value = "CEMS NAME";
  seed.getCell(2, 3).value = "DATE";
  seed.getCell(1, 4 + columns.length).value = "Percentage Valid Data (%)";
  seed.getCell(2, 4 + columns.length).value = "Total";
  seed.getCell(1, 5 + 2 * columns.length).value = "Percentage Comply Data (%)";
  seed.getCell(2, 5 + 2 * columns.length).value = "Total";

  const cems = data?.filter(({ cemsId: id }) => id);
  columns?.forEach((param, idx, arr) => {
    seed.getCell(2, 4 + idx).value = `${param.name} (${param.uom})`;
    seed.getCell(2, 5 + idx + arr.length).value = `${param.name} (${param.uom})`;
    seed.getCell(2, 6 + idx + 2 * arr.length).value = `${param.name} (${param.uom})`;
  });

  data?.forEach((row, idx) => {
    seed.getCell(3 + idx, 1).value = row.cemsId;
    seed.getCell(3 + idx, 2).value = row.cems;
    seed.getCell(3 + idx, 3).value = moment(row.date).format(DATE_FORMAT);
    seed.getCell(3 + idx, 4 + row?.values?.length).value = row.payload.percentage_valid;
    seed.getCell(3 + idx, 5 + 2 * row?.values?.length).value = row.payload.total_percentage_comply;

    row.values?.forEach((val, col, arr) => {
      seed.getCell(3 + idx, 4 + col).value = val?.avg_raw;
      seed.getCell(3 + idx, 5 + col + arr.length).value = val?.avg_valid;
      seed.getCell(3 + idx, 6 + col + 2 * arr.length).value = val?.percentage_comply;
    });
  });
  seed.getCell(cems.length + 3, 3).value = "MAX";
  seed.getCell(cems.length + 4, 3).value = "MIN";
  seed.getCell(cems.length + 5, 3).value = "AVG";
  // console.log(loadCols)
  columns.concat(loadCols)?.forEach((row, idx) => {
    // console.log(idx)
    const [rowStart, rowEnd, col] = [3, cems.length + 2, colName(idx + 3)];
    // const totalin =   cems.length
    seed.getCell(cems.length + 3, 4 + idx).value = {
      formula: `MAX(${col + rowStart}:${col + rowEnd})`,
      date1904: false,
    };
    seed.getCell(cems.length + 4, 4 + idx).value = {
      formula: `MIN(${col + rowStart}:${col + rowEnd})`,
      date1904: false,
    };
    seed.getCell(cems.length + 5, 4 + idx).value = {
      formula: `AVERAGE(${col + rowStart}:${col + rowEnd})`,
      date1904: false,
    };
  });
  // percentage comply
  // console.log(columns)
  columns.concat(loadCols)?.forEach((row, idx) => {
    const [rowStart, rowEnd, col] = [3, cems.length + 2, colName(columns.length + 2 + idx + 3)];
    seed.getCell(cems.length + 3, 4 + columns.length + 2 + idx).value = {
      formula: `MAX(${col + rowStart}:${col + rowEnd})`,
      date1904: false,
    };
    seed.getCell(cems.length + 4, 4 + columns.length + 2 + idx).value = {
      formula: `MIN(${col + rowStart}:${col + rowEnd})`,
      date1904: false,
    };
    seed.getCell(cems.length + 5, 4 + columns.length + 2 + idx).value = {
      formula: `AVERAGE(${col + rowStart}:${col + rowEnd})`,
      date1904: false,
    };
  });
  Download(book, name);
};

export const alertsToExcel = (data: AlertResponse[], name: string = "Alert Log") => {
  const book = new Excel.Workbook();
  const seed = book.addWorksheet("Alert Log");

  seed.getCell(1, 1).value = "Time";
  seed.getCell(1, 2).value = "Mills";
  seed.getCell(1, 3).value = "CEMS";
  seed.getCell(1, 4).value = "Activity";
  seed.getCell(1, 5).value = "Description";

  data?.forEach((row, idx) => {
    seed.getCell(2 + idx, 1).value = row.created_at;
    seed.getCell(2 + idx, 2).value = row.mil.name;
    seed.getCell(2 + idx, 3).value = row.cem?.name;
    seed.getCell(2 + idx, 4).value = row.activity;
    seed.getCell(2 + idx, 5).value = row.description.replace(/\d+\.\d{2,}/g, (a) => Number(a).toFixed(2));
  });
  Download(book, name);
};

const Download = (book: Excel.Workbook, name: string) =>
  book.xlsx
    .writeBuffer({})
    .then(function (xls64) {
      // build anchor tag and attach file (works in chrome)
      var a = document.createElement("a");
      var data = new Blob([xls64], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      var url = URL.createObjectURL(data);
      a.href = url;
      a.download = name + ".xlsx";
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    })
    .catch(function (error) {
      console.log(error.message);
    });
