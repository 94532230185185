import { BsEye, BsFileEarmarkArrowDown, BsPencil, BsTrash } from "react-icons/bs";
import { FunctionComponent, h } from "preact";
import { useState } from "preact/hooks";

import AddNCR from "./add-ncr";
import { BiMapPin } from "react-icons/bi";
import { Button } from "../../../parts/buttons";
import Container from "../../../parts/container";
import NCRDetails from "./details-ncr";
import {
  Role,
  RoleListManagerAndSustainDiv,
  RoleListOperatorOnly,
  RoleListWithoutMember,
  RoleListWithoutMemberAndOperator,
} from "../../../enum/roles.enum";
import TopBar from "../../../components/topbar";
import TopFilter from "../../../components/TopFilter";
import UserRoles from "./user-roles";
import useAllNCR from "../../../hooks/useAllNCR";
import useAuth from "../../../hooks/useAuth";
import { useConfirm } from "../../../context/useConfirm";
import { useEffect } from "preact/hooks";
import useMills from "../../../hooks/useMills";
import useModal from "../../../hooks/useModal";
import useObjectState from "../../../hooks/useObjectState";
import useTopFilter from "../../../hooks/useTopFilter";
import RenderIf from "../../../components/RenderIf";
import { roleCheck } from "../../../utils/permissions";
import useProfile from "../../../hooks/useProfile";
import Loading from "../../../components/loading";
import usePagination from "../../../hooks/usePagination";
import Pagination from "../../../components/pagination";

type NCRListPageProps = h.JSX.HTMLAttributes<HTMLDivElement>;

const NCRListPage: FunctionComponent<NCRListPageProps> = () => {
  enum Page {
    Edit,
    List,
    Details,
  }
  const filter = useTopFilter();
  const { user } = useAuth();
  const confirmation = useConfirm();

  const [selected, setSelected] = useObjectState({
    mil: "",
    role: Role.Member,
    page: Page.List,
    uuid: "",
    edit_at: 0,
  });

  const [pagination, setPagination] = useObjectState({
    page: 1,
    limit: 10,
  });

  const {
    data: ncr,
    mutate,
    isLoading,
  } = useAllNCR({
    ...filter.output,
    page: pagination.page,
    limit: pagination.limit,
  });

  const { show, onHide, onShow } = useModal();
  const { data: mills } = useMills();
  const [refreshNotif, setRefreshNotif] = useState<boolean>(false);
  const { isLogin, fetcher } = useAuth();
  const approve = useModal();
  const userProfile = useProfile();

  const pageHook = usePagination({
    count: ncr?.meta.total_data || 0,
    step: pagination.limit,
    callback: (page: any) => setPagination({ page }),
    pageTotal: ncr?.meta.last_page,
  });

  const onBack = () => {
    setSelected({ page: Page.List, uuid: "", edit_at: new Date().getTime() });
    approve.onHide();
    setApprovalBody({ reason: "" });
    onHide();
    mutate();
    setRefreshNotif((prev: boolean) => !prev);

    setTimeout(() => {
      setRefreshNotif((prev: boolean) => !prev);
    }, 500);
  };
  const [approvalBody, setApprovalBody] = useObjectState({
    status: "Approve",
    reason: "",
    type: 0,
  });

  useEffect(() => {
    isLogin().then((u) => {
      if (u) setSelected({ role: u.role });
    });
  }, []);

  const handleDelete = (uuid: string) => {
    confirmation(
      "Are you sure you want to delete it?",
      (isDelete) => {
        if (isDelete)
          fetcher()
            .delete("/ncr/" + uuid)
            .then(() => {
              onBack();
            })
            .catch(console.error);
      },
      { yes: "Delete", no: "Cancel" }
    );
  };

  const handleSubmit = () => {
    fetcher()
      .put(`/ncr/${approvalBody.type == 3 ? "validation" : "approval"}/${selected.uuid}`, approvalBody)
      .then(() => onBack())
      .finally(() => {
        setRefreshNotif((prev: boolean) => !prev);

        setTimeout(() => {
          setRefreshNotif((prev: boolean) => !prev);
        }, 500);
      });
  };

  useEffect(() => {
    if (user?.area) {
      setSelected({ mil: user?.mil?.uuid ? user?.mil?.uuid : user?.mil });
    }
  }, [user?.area]);

  if (selected.page == Page.Edit) return <AddNCR uuid={selected.uuid} onBack={onBack} />;
  if (selected.page == Page.Details) return <NCRDetails uuid={selected.uuid} onBack={onBack} />;

  return (
    <div>
      {/* modal */}
      <form
        className="modal-backdrop"
        style={{
          display: approve.show ? "inherit" : "none",
          backgroundColor: "#00000099",
          overflowY: "auto",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <div class="modal-content card">
            <div className="card-body text-center h5">Approval</div>
            <div className="card-body">
              <select
                value={approvalBody.status}
                onChange={({ currentTarget: e }) => setApprovalBody({ status: e.value })}
                required
                className="form-control text-center rounded-pill"
              >
                <option value="Approve">APPROVE</option>
                <option value="Reject">REJECT</option>
              </select>
              <div className="my-3">
                <textarea
                  className="form-control"
                  type="text"
                  required
                  placeholder="Isi dengan alasan Approval"
                  value={approvalBody.reason}
                  onChange={({ currentTarget: e }) => setApprovalBody({ reason: e.value })}
                />
              </div>
            </div>
            <div className="card-body d-flex justify-content-center">
              <Button onClick={approve.onHide} type="button" variant="tertiary" className="m-1">
                Cancel
              </Button>
              <Button variant="primary" type="submit" className="m-1">
                Select
              </Button>
            </div>
          </div>
        </div>
      </form>
      {/* end-modal */}
      <UserRoles show={show} onHide={onHide} />
      <TopBar title="SISPEK" subtitle="NCR" refreshNotif={refreshNotif}>
        <TopFilter hook={filter} />
        <select
          value={selected.mil}
          onChange={({ currentTarget: e }) => setSelected({ mil: e.value })}
          className="w-auto form-select rounded-pill"
        >
          {!user?.mil && <option value="">All Mills</option>}
          {mills?.map(({ uuid, name }) => (
            <option value={uuid} key={uuid}>
              {name}
            </option>
          ))}
        </select>
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between align-items-end">
              <div className="">
                <h3>NCR</h3>
                <div className="">
                  Level: <span className="text-primary">{selected.role}</span>
                </div>
              </div>
              <div className="">
                <RenderIf
                  isTrue={roleCheck({
                    roles: RoleListOperatorOnly,
                    currentRole: userProfile?.role,
                  })}
                >
                  <Button variant="primary" className="ms-2" onClick={() => setSelected({ page: Page.Edit })}>
                    ADD NEW ENTRY
                  </Button>
                </RenderIf>
                <Button variant="primary" className="ms-2" onClick={onShow}>
                  SEE ROLES DESCRIPTION
                </Button>
                <Button variant="primary" className="ms-2 d-none" icon={BsFileEarmarkArrowDown}>
                  EXPORT
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <table className="table table-striped table-center">
              <thead>
                <tr>
                  <th>LK Number</th>
                  <th>Date</th>
                  <th>Mill/Unit</th>
                  <th>Creator</th>
                  <th>First Approval</th>
                  <th>Second Approval</th>
                  <th>Validation Status</th>
                  <th colSpan={2}>Menu</th>
                </tr>
              </thead>
              <tbody>
                <RenderIf isTrue={isLoading}>
                  <tr>
                    <td colSpan={8}>
                      <Loading />
                    </td>
                  </tr>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                  {ncr && ncr?.data.length !== 0 ? (
                    ncr?.data
                      .filter(({ mil }) => !selected.mil || mil.uuid == selected.mil)
                      .sort(
                        (a: any, b: any) =>
                          new Date(b.ncr_status_history[0]?.created_at).getTime() -
                          new Date(a.ncr_status_history[0]?.created_at).getTime()
                      )
                      .map((n) => (
                        <tr key={n?.uuid}>
                          <td>{n?.lk_number}</td>
                          <td>{n?.date}</td>
                          <td>{n?.mil?.name}</td>
                          <td>
                            {n?.ncr_status_history.length == 1 ? (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[0].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[0].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[0].status}</li>
                              </ul>
                            ) : n?.ncr_status_history.length == 2 ? (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[1].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[1].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[1].status}</li>
                              </ul>
                            ) : n?.ncr_status_history.length == 3 ? (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[2].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[2].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[2].status}</li>
                              </ul>
                            ) : (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[3].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[3].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[3].status}</li>
                              </ul>
                            )}
                          </td>
                          <td>
                            {n?.ncr_status_history.length == 2 ? (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[0].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[0].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[0].status}</li>
                              </ul>
                            ) : n?.ncr_status_history.length == 3 ? (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[1].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[1].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[1].status}</li>
                              </ul>
                            ) : n?.ncr_status_history.length == 4 ? (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[2].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[2].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[2].status}</li>
                              </ul>
                            ) : (
                              n?.first_approval
                            )}
                          </td>
                          <td>
                            {n?.ncr_status_history.length == 3 ? (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[0].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[0].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[0].status}</li>
                              </ul>
                            ) : n?.ncr_status_history.length == 4 ? (
                              <ul style={"text-align:left"}>
                                <li>Name : {n?.ncr_status_history[1].created_by.name}</li>
                                <li>Role : {n?.ncr_status_history[1].created_by.role}</li>
                                <li>Status : {n?.ncr_status_history[1].status}</li>
                              </ul>
                            ) : (
                              n?.second_approval
                            )}
                          </td>
                          <td>{n?.validator}</td>
                          <td>
                            <Button
                              className="me-2"
                              variant="primary"
                              icon={BsEye}
                              onClick={() => setSelected({ uuid: n?.uuid, page: Page.Details })}
                            />
                            <RenderIf
                              isTrue={roleCheck({
                                roles: RoleListWithoutMember,
                                currentRole: userProfile?.role,
                              })}
                            >
                              <Button
                                className="me-2"
                                variant="secondary"
                                icon={BsPencil}
                                onClick={() =>
                                  setSelected({
                                    uuid: n?.uuid,
                                    page: Page.Edit,
                                    edit_at: new Date().getTime(),
                                  })
                                }
                              />
                            </RenderIf>
                            <RenderIf
                              isTrue={roleCheck({
                                roles: RoleListWithoutMemberAndOperator,
                                currentRole: userProfile?.role,
                              })}
                            >
                              <Button
                                className="me-2"
                                variant="tertiary"
                                icon={BsTrash}
                                onClick={() => handleDelete(n?.uuid)}
                              />
                            </RenderIf>
                          </td>
                          <RenderIf
                            isTrue={roleCheck({
                              roles: RoleListWithoutMemberAndOperator,
                              currentRole: userProfile?.role,
                            })}
                          >
                            <td>
                              <Button
                                variant="secondary"
                                icon={BiMapPin}
                                onClick={() => {
                                  setSelected({ uuid: n?.uuid });
                                  setApprovalBody({
                                    type:
                                      selected.role !== Role.SustainabilityDiv
                                        ? n?.first_approval == "Approve"
                                          ? 2
                                          : 1
                                        : 3,
                                  });
                                  approve.onShow();
                                }}
                              />
                            </td>
                          </RenderIf>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={8}>No Data</td>
                    </tr>
                  )}
                </RenderIf>
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <Pagination hook={pageHook} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NCRListPage;
