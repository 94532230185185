import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { FunctionComponent, h } from "preact";

import { PaginationData } from "../../hooks/usePagination";
import style from "./style.scss";

type PaginationProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  hook: PaginationData;
};

const Pagination: FunctionComponent<PaginationProps> = ({ hook }) => {
  const pageTotalForGenerateButton = hook.isFromServer ? hook.maxPage : hook.maxPage + 1;
  return (
    <div className={style.pagination}>
      <button onClick={() => hook.setPage(0)} className={style.left}>
        <BiChevronsLeft />
      </button>
      <button onClick={hook.prev} className={style.left}>
        <BiChevronLeft />
      </button>
      {Array.from({ length: pageTotalForGenerateButton }).map(
        (_, idx) =>
          idx > hook.page - 3 &&
          idx < hook.page + 3 && (
            <button
              key={idx}
              onClick={() => hook.setPage(idx)}
              className={`${style.item} ${idx == hook.page ? style.active : " "}`}
            >
              {idx + 1}
            </button>
          )
      )}
      <button onClick={hook.next} className={style.right}>
        <BiChevronRight />
      </button>
      <button onClick={() => hook.setPage(hook.maxPage)} className={style.right}>
        <BiChevronsRight />
      </button>
    </div>
  );
};

export default Pagination;
