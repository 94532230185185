import { ApiResponse } from "../types/api-response";
import { Cems } from "../types/cems";
import useAuth from "./useAuth";
import useSWR from "swr";
import { Area } from "../enum/area.enum";

const useCems = (uuid?: string, area?: Area | string) => {
  const { fetcher } = useAuth();
  const url = `/cems/${uuid ? uuid : `?area=${area || ""}`}`;

  const { data, mutate, error } = useSWR(url, (url) =>
    fetcher()
      .get<ApiResponse<Cems[]>>(url)
      .then(({ data }) => {
        return data.data;
      })
  );

  return { data, mutate, error, isLoading: !error && !data };
};
export default useCems;
