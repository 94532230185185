import { ApiResponse } from "../types/api-response";
import { TopFilterOutput } from "./useTopFilter";
import useAuth from "./useAuth";
import useSWR from "swr";
import { PaginationQuery } from "./useAlerts";
export interface NCRResponse {
  uuid: string;
  lk_number: string;
  date: string;
  area: string;
  parameter: string;
  condition: string;
  description: string;
  problem: string;
  action: string;
  completion_date_target: string;
  first_approval: string;
  second_approval: string;
  validator: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  mil: Mil;
  cem: Cem;
  ncr_status_history: NcrStatusHistory[];
}

interface Mil {
  uuid: string;
  name: string;
}

interface Cem {
  uuid: string;
  name: string;
}

interface NcrStatusHistory {
  uuid: string;
  status: string;
  reason: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  created_by: CreatedBy;
}

interface CreatedBy {
  uuid: string;
  name: string;
  email: string;
  role: string;
}

interface ResponseInterface {
  meta: {
    page: number;
    last_page: number;
    length: number;
    total_data: number;
  };
  data: NCRResponse[];
}

const useAllNCR = (filter: TopFilterOutput & PaginationQuery) => {
  const { _type, time, page, limit, ...data } = filter;
  const url = `/ncr/${_type}?page=${page}&limit=${limit}`;
  const { fetcher } = useAuth();
  const output = useSWR([url, JSON.stringify(data)], (url, param) =>
    fetcher()
      .get<ApiResponse<ResponseInterface>>(url, { params: JSON.parse(param) })
      .then(({ data }) => data.data)
  );

  return {
    ...output,
    isLoading: !output.error && !output.data,
  };
};

export default useAllNCR;
