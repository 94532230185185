import { FunctionComponent, h } from "preact"

type DropdownProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  show?: boolean
}

const Dropdown: FunctionComponent<DropdownProps> = ({ children, show }) => {
  return (
    <div class="dropdown">
      {show && (
        <ul class="dropdown-menu show" aria-labelledby="dropdownMenuLink">
          {children}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
