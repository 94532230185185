import "leaflet/dist/leaflet.css";

import { FunctionComponent, h } from "preact";
import { MapContainer, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";

import Container from "../../../parts/container";
import { LatLngTuple } from "leaflet";
import TopBar from "../../../components/topbar";
import { iconMap } from "./icon";
import useMils from "../../../hooks/useMills";
import { useState } from "preact/hooks";
import useAuth from "../../../hooks/useAuth";

type DashboardMapProps = h.JSX.HTMLAttributes<HTMLDivElement>;

const DashboardMap: FunctionComponent<DashboardMapProps> = () => {
  const position: LatLngTuple = [-6.02, 110.505];
  const { user } = useAuth();
  const [selected, setSelected] = useState("");
  const { data } = useMils();
  if (!data) return <div>loading...</div>;
  return (
    <div class="w-100">
      <TopBar title="SISPEK" subtitle="Maps"></TopBar>
      <Container>
        <div className="card">
          <div className="card-body">
            <div className="fs-4 mb-3">
              <span>Maps</span>
              <select
                name=""
                className="ms-3 w-auto form-select rounded-pill d-inline-block"
                id=""
                onChange={({ currentTarget }) => {
                  setSelected(currentTarget.value);
                }}
              >
                {!user?.mil && <option value="">All Mills</option>}
                {data.map(({ name, uuid }) => (
                  <option key={uuid} value={uuid}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <MapContainer
                center={position}
                zoom={5}
                style={{ height: "600px" }}
                zoomControl={false}
                // scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
                <ZoomControl position="bottomright" />
                {data
                  .filter(({ uuid }) => uuid === selected || !selected)
                  .map(({ coordinate, name }, idx) => {
                    const position: LatLngTuple = [parseFloat(coordinate.lat), parseFloat(coordinate.long)];
                    return (
                      <Marker key={idx} icon={iconMap} position={position} title={name}>
                        <Popup>
                          <div className="label">{name}</div>
                          <table style={{ fontSize: "small" }}>
                            <tr>
                              <td style={{ fontWeight: "bold" }}>Latitude</td>
                              <td>
                                {`: 
                            ${Math.abs(position[0]).toFixed(2)}
                            ${position[0] < 0 ? "LS" : "LU"}
                            `}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ fontWeight: "bold" }}>Longitude</td>
                              <td>
                                {`: 
                            ${Math.abs(position[1]).toFixed(2)}
                            ${position[1] < 0 ? "BB" : "BT"}
                            `}
                              </td>
                            </tr>
                          </table>
                        </Popup>
                      </Marker>
                    );
                  })}
              </MapContainer>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DashboardMap;
