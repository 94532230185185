import { FunctionComponent, h, Fragment } from "preact";
import useRaw, { PayloadPercentage } from "../../hooks/useRaw";
import style from "./style.scss";

import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { Button } from "../../parts/buttons";
import Container from "../../parts/container";
import Pagination from "../../components/pagination";
import { SensorTypeQuery } from "../../enum/sensor.enum";
import TopBar from "../../components/topbar";
import TopFilter from "../../components/TopFilter";
import distinctSensor from "../../utils/distinctSensor";
import moment from "moment";
import { percentageToExcel } from "./toexcel";
import useCems from "../../hooks/useCems";
import useMills from "../../hooks/useMills";
import useObjectState from "../../hooks/useObjectState";
import usePagination from "../../hooks/usePagination";
import useTopFilter from "../../hooks/useTopFilter";
import { Area } from "../../enum/area.enum";
import useArea from "../../hooks/useArea";
import { useEffect } from "preact/hooks";
import Loading from "../../components/loading";
import RenderIf from "../../components/RenderIf";
import useAuth from "../../hooks/useAuth";

type RecordRawPageProps = h.JSX.HTMLAttributes<HTMLDivElement>;

const RecordRawPage: FunctionComponent<RecordRawPageProps> = () => {
  const topFilter = useTopFilter();
  const { user } = useAuth();
  const [currentArea, storeArea, removeArea] = useArea(Area.PowerPlant);
  const [filter, setFilter] = useObjectState({
    mil: "",
    cems: "",
    area: user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant,
  });
  const { data: mills } = useMills();
  const { data: cems } = useCems("", filter.area);
  const { data, isLoading } = useRaw({
    mils: filter.mil,
    type: SensorTypeQuery.percentage,
    filter: topFilter.output,
    area: filter.area,
  });

  const columns = data ? distinctSensor(data, filter.area) : [];
  const tableData = data
    ?.map((comp) =>
      comp.mils
        .filter(({ uuid }) => !filter.mil || uuid == filter.mil)
        .map(({ cems }) =>
          cems
            .filter(({ uuid }) => !filter.cems || uuid == filter.cems)
            .map((cem) =>
              cem.payloads?.map((payload) => {
                const values = columns.map((col) => {
                  const data = payload.payloads.find(({ name }) => name == col.name) as PayloadPercentage;
                  return data;
                });
                return {
                  cemsId: cem.uuid,
                  cems: cem.name,
                  date: payload.measured_at,
                  payload,
                  values,
                };
              })
            )
            .reduce((a, b) => (b ? a?.concat(b) : a), [])
        )
        .reduce((a, b) => (b ? a?.concat(b) : a), [])
    )
    .reduce((a, b) => (b ? a?.concat(b) : a), [])
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const pages = usePagination({ count: tableData?.length || 0, step: 20 });
  const showTable = tableData?.slice(pages.output, pages.output + pages.step);
  // .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  // localStorage.setItem('data',JSON.stringify(showTable))
  // localStorage.setItem('kolom',JSON.stringify(columns))
  const handleExport = (tableData: any) => {
    if (tableData) percentageToExcel(columns, tableData);

    // if (showTable) percentageToExcel(columns, showTable)
    // var data = localStorage.getItem('data')
    // var kolomkan = localStorage.getItem('kolom')
    // if (data) percentageToExcel(kolomkan, JSON.parse(data))
  };
  // console.log(tableData)

  const handleChangeArea = (value: any) => {
    storeArea(value, () =>
      setFilter({
        ...filter,
        area: value,
      })
    );
  };

  const getMinValueOfNumbers = (arr: (number | undefined)[]) => {
    const values = arr?.filter((item) => typeof item === "number") as number[];
    const value = values.length !== 0 ? Math.min(...values)?.toFixed(2) : "";
    return value;
  };

  const getMaxValueOfNumbers = (arr: (number | undefined)[]) => {
    const values = arr?.filter((item) => typeof item === "number") as number[];
    const value = values.length !== 0 ? Math.max(...values)?.toFixed(2) : "";
    return value;
  };

  const getAverageOfNumbers = (arr: (number | undefined)[]) => {
    const values = arr?.filter((item) => typeof item === "number") as number[];
    const total = values?.reduce((a, b) => a + b, 0);
    const avg = values.length !== 0 ? (total / values.length)?.toFixed(2) : "";

    return avg;
  };

  const getMinValue = (a: number | undefined, b: number | undefined) => {
    if (typeof a === "number" && typeof b === "number") {
      return Math.min(a, b);
    }

    return typeof a === "number" ? a : b;
  };

  const getMaxValue = (a: number | undefined, b: number | undefined) => {
    if (typeof a === "number" && typeof b === "number") {
      return Math.max(a, b);
    }

    return typeof a === "number" ? a : b;
  };

  useEffect(() => {
    if (currentArea) {
      setFilter({
        ...filter,
        area: currentArea,
      });
    }
  }, [currentArea]);

  useEffect(() => {
    if (user?.area) {
      storeArea(user?.area !== Area.NonPowerPlant ? Area.PowerPlant : Area.NonPowerPlant);
      setFilter({
        mil: user?.mil?.uuid ? user?.mil?.uuid : user?.mil,
        cems: user?.cems.length !== 0 ? user?.cems[0].uuid : "",
      });
    }
  }, [user?.area]);

  useEffect(() => {
    if (!user?.mil && mills?.length !== 0) {
      setFilter({
        mil: mills && mills[0].uuid,
      });
    }
  }, [mills, user?.mil]);

  return (
    <div className="min-vh-100 w-auto">
      <TopBar title="SISPEK" subtitle="Records Percentage Data">
        <TopFilter hook={topFilter} />
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title">
            <h3>Percentage Data</h3>
          </div>
          <div className="card-body">
            <div className="d-flex mb-3">
              <select
                value={filter.mil}
                onChange={({ currentTarget }) => setFilter({ ...filter, mil: currentTarget.value, cems: "" })}
                className="me-2 w-auto form-select rounded-pill"
              >
                {mills?.map((mil) => (
                  <option key={mil.uuid} value={mil.uuid}>
                    {mil.name}
                  </option>
                ))}
                {!user?.mil && <option value="">All Mills</option>}
              </select>
              <select
                className="me-2 w-auto form-select rounded-pill"
                onChange={({ currentTarget }) => handleChangeArea(currentTarget.value)}
                value={filter.area}
              >
                {Object.values(Area)
                  ?.filter((item) => (user?.area?.toLowerCase() === "all" ? item : item === user?.area))
                  .map((area) => (
                    <option key={area} value={area}>
                      {area}
                    </option>
                  ))}
              </select>
              <select
                value={filter.cems}
                onChange={({ currentTarget }) => setFilter({ cems: currentTarget.value })}
                className="me-2 w-auto form-select rounded-pill"
              >
                {user?.cems.length === 0 && <option value="">Select CEMS</option>}
                {user?.cems?.length !== 0
                  ? user?.cems?.map((cem) => (
                      <option key={cem.uuid} value={cem.uuid}>
                        {cem.name}
                      </option>
                    ))
                  : cems
                      ?.filter(({ mil }) => mil.uuid == filter.mil || !filter.mil)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((cem) => (
                        <option key={cem.uuid} value={cem.uuid}>
                          {cem.name}
                        </option>
                      ))}
              </select>

              <Button
                className="ms-auto"
                icon={BsFileEarmarkArrowDown}
                variant="primary"
                onClick={() => {
                  if (tableData) percentageToExcel(columns, tableData);
                }}
                // onClick={() => handleExport(showTable)}
              >
                Export
              </Button>
            </div>
            <div className="overflow-auto">
              <table className="table table-sm table-striped table-data table-center">
                <thead>
                  <tr>
                    <th rowSpan={2}>CEMS</th>
                    <th rowSpan={2}>Date</th>
                    <th rowSpan={2}>Time</th>
                    {columns?.map((col) => (
                      <th rowSpan={2} key={col.name}>
                        {col.name} <br /> {col.uom}
                      </th>
                    ))}
                    <th colSpan={columns.length + 1}>Percentage Valid Data (%)</th>
                    <th colSpan={columns.length + 1}>Percentage Comply Data (%)</th>
                  </tr>
                  <tr>
                    <th>Total</th>
                    {columns?.map((col) => (
                      <th key={col.name}>{col.name}</th>
                    ))}
                    <th>Total</th>
                    {columns?.map((col) => (
                      <th key={col.name}>{col.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className={style.emission__data}>
                  <RenderIf isTrue={isLoading}>
                    <tr className="text-center">
                      <td className="text-center py-4" colSpan={3 * columns.length + 5}>
                        <Loading />
                      </td>
                    </tr>
                  </RenderIf>
                  <RenderIf isTrue={!isLoading}>
                    {!filter.cems ? (
                      <tr>
                        <td style={{ padding: "30px" }} colSpan={columns.length * 3 + 5}>
                          Select CEMS
                        </td>
                      </tr>
                    ) : showTable?.length ? (
                      <Fragment>
                        {showTable?.map((row, idx) => (
                          <tr key={`row-${idx}`}>
                            <td>{row.cems}</td>
                            <td>{moment(row.date).format("YYYY-MM-DD")}</td>
                            <td>{moment(row.date).format("HH:mm")}</td>
                            {row.values.map((v, idx) => (
                              <td key={`params-${idx}`}>{v?.avg_raw?.toFixed(2)}</td>
                            ))}
                            <td
                              className={
                                row.payload.percentage_valid && row.payload.percentage_valid < 75
                                  ? "bg-error text-white"
                                  : ""
                              }
                            >
                              {row.payload.percentage_valid.toFixed(2)}
                            </td>
                            {row.values.map((v, idx) => (
                              <td
                                key={`params-${idx}`}
                                className={v?.avg_valid && v?.avg_valid < 75 ? "bg-error text-white" : ""}
                              >
                                {v?.avg_valid?.toFixed(2)}
                              </td>
                            ))}
                            <td
                              className={
                                row.payload.total_percentage_comply && row.payload.total_percentage_comply < 95
                                  ? "bg-error text-white"
                                  : ""
                              }
                            >
                              {row.payload.total_percentage_comply?.toFixed(2)}
                            </td>
                            {row.values.map((v, idx) => (
                              <td
                                key={`params-${idx}`}
                                className={
                                  v?.percentage_comply && v?.percentage_comply < 95 ? "bg-error text-white" : ""
                                }
                              >
                                {v?.percentage_comply?.toFixed(2)}
                              </td>
                            ))}
                          </tr>
                        ))}
                        <tr className={style.max}>
                          <td style={{ backgroundColor: "white" }} rowSpan={3} />
                          <td rowSpan={3} className={style.avg}>
                            Percentage
                          </td>
                          <td>MAX</td>
                          {columns?.map((_, colIdx) => {
                            const value = getMaxValueOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.avg_raw)
                            );
                            return <td key={colIdx}>{value}</td>;
                          })}
                          <td>
                            {getMaxValueOfNumbers(
                              (tableData || [])?.map((row) => row.payload?.percentage_valid as number | undefined)
                            )}
                          </td>
                          {columns?.map((_, colIdx) => {
                            const value = getMaxValueOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.avg_valid)
                            );
                            return <td key={colIdx}>{value}</td>;
                          })}
                          <td>
                            {getMaxValueOfNumbers(
                              (tableData || [])?.map((row) => row.payload?.total_percentage_comply)
                            )}
                          </td>
                          {columns?.map((_, colIdx) => {
                            const value = getMaxValueOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.percentage_comply)
                            );
                            return <td key={colIdx}>{value}</td>;
                          })}
                        </tr>
                        <tr className={style.min}>
                          <td>MIN</td>
                          {columns?.map((_, colIdx) => {
                            const value = getMinValueOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.avg_raw)
                            );
                            return <td key={colIdx}>{value}</td>;
                          })}
                          <td>
                            {getMinValueOfNumbers((tableData || [])?.map((row) => row.payload?.percentage_valid))}
                          </td>
                          {columns?.map((_, colIdx) => {
                            const value = getMinValueOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.avg_valid)
                            );
                            return <td key={colIdx}>{value}</td>;
                          })}
                          <td>
                            {getMinValueOfNumbers(
                              (tableData || [])?.map((row) => row.payload?.total_percentage_comply)
                            )}
                          </td>
                          {columns?.map((_, colIdx) => {
                            const value = getMinValueOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.percentage_comply)
                            );
                            return <td key={colIdx}>{value}</td>;
                          })}
                        </tr>
                        {/* Average */}
                        <tr className={style.avg}>
                          <td>AVG</td>
                          {columns?.map((_, colIdx) => {
                            const avg = getAverageOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.avg_raw)
                            );

                            return <td key={colIdx}>{avg}</td>;
                          })}

                          <td>{getAverageOfNumbers((tableData || [])?.map((row) => row.payload?.percentage_valid))}</td>
                          {columns?.map((_, colIdx) => {
                            const avg = getAverageOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.avg_valid)
                            );

                            return <td key={colIdx}>{avg}</td>;
                          })}

                          <td>
                            {getAverageOfNumbers((tableData || [])?.map((row) => row.payload?.total_percentage_comply))}
                          </td>
                          {columns?.map((_, colIdx) => {
                            const avg = getAverageOfNumbers(
                              (tableData || [])?.map((row) => row?.values[colIdx]?.percentage_comply)
                            );
                            return <td key={colIdx}>{avg}</td>;
                          })}
                        </tr>
                      </Fragment>
                    ) : (
                      <tr className="text-center">
                        <td className="text-center py-4" colSpan={3 * columns.length + 5}>
                          No data.
                        </td>
                      </tr>
                    )}
                  </RenderIf>
                </tbody>
              </table>
            </div>
            <div className="my-2 d-flex justify-content-end">
              <Pagination hook={pages} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RecordRawPage;
