import { FunctionComponent, h } from "preact"
import { useEffect, useState } from "preact/hooks"

import { ApiResponse } from "../../../types/api-response"
import { NCRResponse } from "../../../hooks/useAllNCR"
import TopBar from "../../../components/topbar"
import moment from "moment"
import style from "./style.scss"
import useAuth from "../../../hooks/useAuth"

type NCRDetailsProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  onBack?: () => void
  uuid?: string
}

const NCRDetails: FunctionComponent<NCRDetailsProps> = ({ onBack, uuid: ncrId }) => {
  const [res, setRes] = useState<NCRResponse>()
  const { fetcher } = useAuth()

  useEffect(() => {
    fetcher()
      .get<ApiResponse<NCRResponse>>("/ncr/" + ncrId)
      .then(({ data }) => data.data)
      .then(setRes)
      .catch(console.error)
  }, [ncrId])

  return (
    <div>
      <TopBar title="SISPEK" subtitle="NCR" onBack={onBack} />
      <div class="p-3">
        <div className="card">
          <div className="card-body h4">Form</div>
          <div className={style.ncr__details}>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className={style.field}>
                    <div className={style.label}>LK Number</div>
                    <div className={style.value}>{res?.lk_number}</div>
                  </div>
                </div>
                <div className="col">
                  <div className={style.field}>
                    <div className={style.label}>Date</div>
                    <div className={style.value}>{moment(res?.date).format("DD MMMM YYYY")}</div>
                  </div>
                </div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col">
                  <div className={style.field}>
                    <div className={style.label}>Mills/Unit</div>
                    <div className={style.value}>{res?.mil.name}</div>
                  </div>
                </div>
                <div className="col">
                  <div className={style.field}>
                    <div className={style.label}>Area</div>
                    <div className={style.value}>{res?.area}</div>
                  </div>
                </div>
                <div className="col">
                  <div className={style.field}>
                    <div className={style.label}>CEMS</div>
                    <div className={style.value}>{res?.cem.name}</div>
                  </div>
                </div>
              </div>
              <div className={style.field}>
                <div className={style.label}>Parameters</div>
                <div className={style.value}>{res?.parameter}</div>
              </div>
              <div className={style.field}>
                <div className={style.label}>Condition</div>
                <div className={style.value}>{res?.condition}</div>
              </div>
              <div className={style.field}>
                <div className={style.label}>Description</div>
                <div className={style.value}>{res?.description}</div>
              </div>
              <div className={style.field}>
                <div className={style.label}>Root Problem Analytics</div>
                <div className={style.value}>{res?.problem}</div>
              </div>
              <div className={style.field}>
                <div className={style.label}>Repair Action & Prevention</div>
                <div className={style.value}>{res?.action}</div>
              </div>
              <div className={style.field}>
                <div className={style.label}>Refinement Due Date</div>
                <div className={style.value}>{moment(res?.completion_date_target).format("DD/MM/YYYY")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NCRDetails
