import { Fragment, FunctionComponent, h } from "preact";

import { BiChevronLeft } from "react-icons/bi";
import { SensorTypeFilter } from "../../enum/sensor.enum";
import style from "./style.scss";
import { useEffect } from "preact/hooks";
import TopBarAlert from "./alert";
import React from "react";
import useNotificationBanners from "../../hooks/useNotificationBanners";

type TopBarProps = h.JSX.HTMLAttributes<HTMLDivElement> & {
  title?: string;
  subtitle?: string;
  onBack?: () => void;
  refreshNotif?: boolean;
};

const TopBar: FunctionComponent<TopBarProps> = ({ title, onBack, subtitle, children, refreshNotif = false }) => {
  const { data: note, mutate } = useNotificationBanners({
    page: 1,
    limit: 100,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      mutate();
    }, 60 * 6000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    refreshNotif && mutate();
  }, [refreshNotif]);

  const notificationData = note?.data;

  return (
    <Fragment>
      <div>
        <marquee className="bg-warning text-center">
          {notificationData
            ?.filter(({ type: notificationType }) => notificationType === "Announcement")
            .map(({ message }, index) => (
              <React.Fragment key={index}>
                <span className="d-inline-block mx-5">
                  {message?.replace(/\d+\.\d{2,}/g, (a) => Number(a).toFixed(2))}
                </span>{" "}
                |
              </React.Fragment>
            ))}
        </marquee>
        <div className="d-flex px-5 py-3 justify-content-between border-bottom">
          <div className={style.nav}>
            <button className={style.back} onClick={onBack}>
              <BiChevronLeft fontSize="1.8em" />
            </button>
            <div className={style.title}>{title}</div>
            <div className={style.subtitle}>{subtitle}</div>
          </div>
          <div className={style.filter}>{children}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default TopBar;
