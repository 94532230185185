import { BsBellFill, BsPencil, BsTrash } from "react-icons/bs";
import { FunctionComponent, h } from "preact";

import { Button } from "../../../parts/buttons";
import Container from "../../../parts/container";
import EditUser from "./edit-user";
import NewUser from "./new-user";
import TopBar from "../../../components/topbar";
import { User } from "../../../types/user";
import UserRoles from "./user-roles";
import { mutate } from "swr";
import useAuth from "../../../hooks/useAuth";
import { useConfirm } from "../../../context/useConfirm";
import useModal from "../../../hooks/useModal";
import { useState } from "preact/hooks";
import useUsers from "../../../hooks/useUsers";
import { Area } from "../../../enum/area.enum";
import RenderIf from "../../../components/RenderIf";
import Loading from "../../../components/loading";

type AdminUserPageProps = h.JSX.HTMLAttributes<HTMLDivElement>;

const AdminUserPage: FunctionComponent<AdminUserPageProps> = () => {
  const { data: users, error, isLoading } = useUsers();
  const { fetcher } = useAuth();
  const [edited, setEdited] = useState<User>();
  const [filter, setFilter] = useState<string>("All");

  const { show: showNewUser, onHide: hideNewUser, onShow: onShowNewUser } = useModal();
  const { show: showEditUser, onHide: hideEditUser, onShow: onShowEditUser } = useModal();
  const { show, onHide, onShow } = useModal();

  const confirmation = useConfirm();
  const handleDelete = (user: User) => () => {
    const msg = <div>Are you sure you want to delete it?</div>;
    confirmation(
      msg,
      () => {
        const url = `/user/${user.uuid}`;
        fetcher()
          .delete(url)
          .then(() => {
            mutate("/user");
          })
          .catch(console.error);
      },
      { yes: "Delete", no: "Cancel" }
    );
  };

  const generateAffiliateFilter = () => {
    if (users && users.length > 0) {
      const temp: { id?: string; name?: string }[] = [
        {
          id: "All",
          name: "All Affiliate",
        },
      ];

      users.forEach((userItem) => {
        if (temp.findIndex((item) => item.id === userItem?.company?.uuid) === -1) {
          temp.push({
            id: userItem?.company?.uuid,
            name: userItem?.company?.name || "HQ",
          });
        }
      });

      return temp;
    }

    return [];
  };

  const affiliateAvailable = generateAffiliateFilter();
  const checkAffiliate = (id: string | undefined, filter: string) =>
    filter === "All" ? (id || id === undefined) : filter === "" ? id === undefined : id === filter;

  if (!users) return <div>Loading...</div>;
  return (
    <div className="min-vh-100">
      <TopBar title="SISPEK" subtitle="User">
        <select
          className="me-2 w-auto form-select rounded-pill"
          onChange={({ currentTarget }) => setFilter(currentTarget.value)}
          value={filter}
        >
          {affiliateAvailable?.map((item) => (
            <option key={item.id || ""} value={item.id || ""}>
              {item.name}
            </option>
          ))}
        </select>
      </TopBar>
      <Container>
        <div className="card">
          <div className="card-title">
            <h3>User</h3>
            <div className="d-flex">
              <Button className="ms-2" onClick={onShowNewUser} variant="primary">
                ADD NEW USER
              </Button>
              <Button className="ms-2" onClick={onShow} variant="primary">
                SEE ROLES DESCRIPTION
              </Button>
            </div>
          </div>
          <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Affiliate</th>
                  <th>Notification</th>
                  <th>Menu</th>
                </tr>
              </thead>
              <tbody>
                <RenderIf isTrue={isLoading}>
                  <tr>
                    <td colSpan={6}>
                      <Loading />
                    </td>
                  </tr>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                  {users && users?.length ? (
                    users
                      ?.filter((item) => checkAffiliate(item.company?.uuid, filter))
                      .sort((a, b) => a.role.localeCompare(b.role))
                      .map((user, idx) => (
                        <tr key={`user-${idx}`}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>{user.company?.name || "HQ"}</td>
                          <td className="text-center">
                            <Button icon={BsBellFill} variant="primary" disabled={!user.notification} />
                          </td>
                          <td className="text-center">
                            <Button
                              className="me-2"
                              variant="secondary"
                              icon={BsPencil}
                              onClick={() => {
                                setEdited(() => {
                                  onShowEditUser();
                                  return user;
                                });
                              }}
                            />
                            <Button className="me-2" variant="tertiary" onClick={handleDelete(user)} icon={BsTrash} />
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={6}>No Data</td>
                    </tr>
                  )}
                </RenderIf>
              </tbody>
            </table>
          </div>
        </div>
        {/* modals */}
        <NewUser show={showNewUser} onHide={hideNewUser} />
        {edited && <EditUser userData={edited} show={showEditUser} onHide={hideEditUser} />}
        <UserRoles show={show} onHide={onHide} />
      </Container>
    </div>
  );
};

export default AdminUserPage;
